import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';

import { getApplicationBasePath } from '@/utils/application';
import { App } from '@/App';
import { AppProviders } from '@/AppProviders';
import { Router } from '@/Router';

import '@/i18n';
import '@/index.css';
import '@knack/asterisk-react/style.css';

const queryClient = new QueryClient();
const applicationBasePath = getApplicationBasePath();

// If the URL is malformed (no account or app slugs), we redirect to the dashboard. This is the same behavior as the v3 builder
if (applicationBasePath === '/') {
  window.location.replace(import.meta.env.PUBLIC_DASHBOARD_URL);
}

async function enableMocking() {
  if (import.meta.env.PUBLIC_IS_MSW_BROWSER_ENABLED !== 'true') {
    return;
  }

  const { worker } = await import('./tests/mocks/msw/browser/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  // eslint-disable-next-line consistent-return
  return worker.start();
}

void enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <AppProviders queryClient={queryClient}>
        <App>
          <Router />
        </App>
      </AppProviders>
    </React.StrictMode>
  );
});
