import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from '@knack/asterisk-react';

import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';

export function NewAIAppModal() {
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const [t] = useTranslation();
  const { data: app } = useApplicationQuery();

  return (
    <Dialog open={shouldShowModal} onOpenChange={setShouldShowModal}>
      <Dialog.Content data-testid="new-ai-app-welcome-modal">
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t('pages.ai_modal.title', {
                app_name: app?.name
              })}
            </Dialog.Title>
          </Dialog.Header>
          <Dialog.Description className="my-4">
            {t('pages.ai_modal.description', {
              table_number: app?.objects.length
            })}
          </Dialog.Description>
          <div className="mb-2 font-semibold">{t('pages.ai_modal.tables')}</div>
          <ul className="list-disc">
            {app?.objects.map((table) => (
              <li key={table.key} className="ml-4">
                {table.name}
              </li>
            ))}
          </ul>
          <div className="mt-4 italic text-subtle">
            <div>{t('pages.ai_modal.note')}</div>
            <div className="mt-4">{t('pages.ai_modal.enjoy')}</div>
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button>{t('pages.ai_modal.see_app')}</Button>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  );
}
