import { useState, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { type IconType } from 'react-icons';
import { HiBolt as DynamicIcon, HiLink as LinkIcon } from 'react-icons/hi2';
import {
  Badge,
  Button,
  Combobox,
  Dialog,
  Tooltip,
  type ComboboxOption
} from '@knack/asterisk-react';

import { type BuilderPage } from '@/types/schema/BuilderPage';
import { type ViewWithDetails } from '@/types/schema/views/ViewWithDetails';
import { usePagesQuery } from '@/hooks/api/queries/usePagesQuery';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';

function DisabledActionButtonTooltip({
  isDisabled,
  children
}: {
  isDisabled?: boolean;
  children: ReactNode;
}) {
  const [t] = useTranslation();

  if (!isDisabled) {
    return children;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <div>{children}</div>
      </Tooltip.Trigger>
      <Tooltip.Content>
        {t('pages.element_settings.common.categories.actions.this_column_already_exists')}
      </Tooltip.Content>
    </Tooltip>
  );
}

export type ActionItem = {
  key: string;
  label: string;
  icon: IconType;
  onClick: () => void;
  isDisabled?: boolean;
  isComingSoon?: boolean;
  dataTestId?: string;
};

export type ActionSettingsProps = {
  actions: ActionItem[];
  onApplyLinkToExistingPage: (page: BuilderPage) => void;
};

export function ActionsSettings({ actions, onApplyLinkToExistingPage }: ActionSettingsProps) {
  const [t] = useTranslation();
  const { sourceObject } = useActiveViewContext<ViewWithDetails>();
  const { data: pages = [] } = usePagesQuery();

  const [isLinkToExitingPageDialogOpen, setIsLinkToExitingPageDialogOpen] = useState(false);
  const [linkToExistingPageSlug, setLinkToExistingPageSlug] = useState({
    key: '',
    label: ''
  });

  const linkToExistingPageAction: ActionItem = {
    key: 'link',
    label: t('pages.element_settings.common.categories.actions.link_record'),
    icon: LinkIcon,
    onClick: () => setIsLinkToExitingPageDialogOpen(true),
    dataTestId: 'button-link-to-existing-page-action'
  };
  const dynamicAction: ActionItem = {
    key: 'dynamic',
    label: t('pages.element_settings.common.categories.actions.dynamic_action'),
    icon: DynamicIcon,
    isComingSoon: true,
    onClick: () => {},
    dataTestId: 'button-dynamic-action-action'
  };
  const allActions = [...actions, linkToExistingPageAction, dynamicAction];

  const handleApplyLinkToExistingPage = () => {
    const selectedPage = pages.find((p) => p.key === linkToExistingPageSlug.key);

    if (!selectedPage) {
      return;
    }

    onApplyLinkToExistingPage(selectedPage);

    setIsLinkToExitingPageDialogOpen(false);
  };

  const getParentPageName = (parentSlug: string | null) => {
    const parentPage = pages.find((p) => p.slug === parentSlug);
    return parentPage?.name || parentSlug;
  };

  // A list of existing pages that share the same source object as the original object
  const childPagesWithSameSourceObject: ComboboxOption[] = [];
  pages.forEach((page) => {
    if (page.parentSlug && page.sourceObjectKey === sourceObject.key) {
      const parentName = getParentPageName(page.parentSlug);

      childPagesWithSameSourceObject.push({
        key: page.key,
        label: `${parentName} > ${page.name}`
      });
    }
  });

  return (
    <>
      <p>{t('pages.element_settings.common.categories.actions.select_action_type')}</p>
      {allActions.map((action) => (
        <DisabledActionButtonTooltip key={action.key} isDisabled={action.isDisabled}>
          <Button
            intent="secondary"
            disabled={action.isComingSoon || action.isDisabled}
            className="w-full justify-start gap-2 font-normal hover:bg-muted"
            onClick={() => action.onClick()}
            data-testid={action.dataTestId}
          >
            <Button.Icon icon={action.icon} />
            {action.label}
            {action.isComingSoon && <Badge>{t('keywords.coming_soon')}</Badge>}
          </Button>
        </DisabledActionButtonTooltip>
      ))}
      <Dialog open={isLinkToExitingPageDialogOpen} onOpenChange={setIsLinkToExitingPageDialogOpen}>
        <Dialog.Content>
          <Dialog.MainContent>
            <Dialog.Header>
              <Dialog.Title>
                {t('pages.element_settings.common.categories.actions.select_page')}
              </Dialog.Title>
              <Dialog.Description>
                {t('pages.element_settings.common.categories.actions.select_page_description')}
              </Dialog.Description>
            </Dialog.Header>
            <Combobox
              id="link-to-existing-page"
              selectedOption={linkToExistingPageSlug || childPagesWithSameSourceObject[0]}
              options={childPagesWithSameSourceObject}
              onSelectOption={(option) => setLinkToExistingPageSlug(option)}
              triggerClassName="mt-4 w-full"
              isSearchEnabled
            />
          </Dialog.MainContent>
          <Dialog.Footer>
            <Dialog.Close asChild>
              <Button intent="secondary">{t('actions.cancel')}</Button>
            </Dialog.Close>
            <Button intent="primary" onClick={handleApplyLinkToExistingPage}>
              {t('actions.apply')}
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </>
  );
}
