import { usePrevious } from '@/hooks/usePrevious';
import { useJobsStore } from '@/components/jobs/useJobsStore';
import { useLastVisitedStore } from '@/pages/tables/useLastVisitedStore';

export function useGetLatestJob() {
  const lastVisitedTableOrRoleKey = useLastVisitedStore((state) => state.lastVisitedTableOrRoleKey);
  const latestJob = useJobsStore((state) =>
    Object.values(state.jobList).findLast((job) => job.tableKey === lastVisitedTableOrRoleKey)
  );
  const previousJobState = usePrevious(latestJob);

  return { latestJob, previousJobState };
}
