import { useEffect, useRef } from 'react';

import { useGlobalState } from '@/hooks/useGlobalStore';
import { cn } from '@/utils/tailwind';
import { LeftSidebar } from '@/components/navigation/LeftSidebar';
import { Header, HEADER_HEIGHT } from './Header';
import { type RightSidebar } from './RightSidebar';

interface MainLayoutProps {
  children: React.ReactNode;
  rightSidebar?: React.ReactElement<typeof RightSidebar>;
}

export function MainLayout({ children, rightSidebar }: MainLayoutProps) {
  const { setMainLayoutContainerRef } = useGlobalState((state) => ({
    setMainLayoutContainerRef: state.actions.setMainLayoutContainerRef
  }));

  const mainContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (mainContainerRef.current) {
      setMainLayoutContainerRef(mainContainerRef.current);
    }
  }, [mainContainerRef, setMainLayoutContainerRef]);

  return (
    <div className="h-screen">
      <Header />
      <div className="flex" style={{ height: `calc(100vh - ${HEADER_HEIGHT}px` }}>
        <LeftSidebar />
        <main
          ref={mainContainerRef}
          className={cn(
            'relative w-full overflow-auto rounded-tl-lg border-l border-t border-subtle',
            {
              'flex h-full': rightSidebar
            }
          )}
        >
          {children}
          {rightSidebar && (
            <div data-testid="right-sidebar" className="z-30 basis-14">
              {rightSidebar}
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
