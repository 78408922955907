import { type KnackField } from '@/types/schema/KnackField';

export function getFilteredFieldActionValueFields(
  targetField: KnackField,
  availableFields: KnackField[]
): KnackField[] {
  switch (targetField.type) {
    case 'signature':
    case 'address':
    case 'name':
    case 'timer':
      return availableFields.filter((f) => f.type === targetField.type);
    case 'file':
    case 'image':
      return availableFields.filter((f) => f.type === 'file' || f.type === 'image');
    case 'connection':
      return [targetField];
    default:
      return availableFields;
  }
}
