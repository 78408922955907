import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type BuilderViewSourceCriteriaRule } from '@/types/schema/BuilderView';
import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { CriteriaForm } from '@/components/CriteriaForm';

type ReportFiltersDialogProps = {
  sourceObject: KnackObject;
  filters: BuilderViewSourceCriteriaRule[];
  onFormSubmit: (data: { criteria: KnackFilter[] }) => void;
};

function ReportFiltersDialogContent({
  sourceObject,
  filters,
  onFormSubmit
}: ReportFiltersDialogProps) {
  const [t] = useTranslation();
  const { getDefaultCriteriaOperator, validateCriteriaValues } = useCriteriaHelpers();

  const filtersSchema = z
    .object({
      criteria: z.custom<KnackFilter[]>()
    })
    .superRefine((data, context) => {
      const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

      if (criteriaValueErrors.length) {
        criteriaValueErrors.forEach((error) => {
          context.addIssue({
            path: error.path,
            message: t(error.message || 'errors.value_required'),
            code: 'custom'
          });
        });
      }
    });

  const form = useForm<{ criteria: KnackFilter[] }>({
    resolver: zodResolver(filtersSchema),
    defaultValues: {
      criteria: filters
    }
  });

  const { append: addRuleField } = useFieldArray({
    control: form.control,
    name: 'criteria'
  });

  const hasFilters = form.getValues('criteria').length > 0;

  const getDefaultFilterCriteria = () => {
    const firstField = sourceObject.fields[0];

    if (!firstField) {
      return undefined;
    }

    const defaultFilterCriteria: BuilderViewSourceCriteriaRule = {
      field: firstField.key,
      operator: getDefaultCriteriaOperator(firstField, 'filter'),
      value: ''
    };

    return defaultFilterCriteria;
  };

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t('pages.element_settings.report.categories.data_display.filters.dialog_title')}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.report.categories.data_display.filters.dialog_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          {hasFilters && (
            <div className="mt-6">
              <p className="mb-4">
                {t(
                  'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
                )}
              </p>
              <div className="space-y-2 rounded-lg bg-subtle p-3">
                <CriteriaForm sourceObject={sourceObject} criteriaType="filter" />
              </div>
            </div>
          )}
          {!hasFilters && (
            <Button
              intent="secondary"
              className="mt-4 gap-1"
              onClick={() => {
                const defaultFilterCriteria = getDefaultFilterCriteria();

                if (!defaultFilterCriteria) {
                  return;
                }

                addRuleField(defaultFilterCriteria);
              }}
              data-testid="filters-modal-add-source-filter-button"
            >
              <Button.Icon icon={AddIcon} />
              {t('pages.element_settings.report.categories.data_display.filters.filters_label')}
            </Button>
          )}
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function ReportFiltersDialog({
  sourceObject,
  filters,
  onFormSubmit
}: ReportFiltersDialogProps) {
  const [t] = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const hasFilters = filters.length > 0;

  const handleReportFiltersSubmit = (data: { criteria: KnackFilter[] }) => {
    onFormSubmit(data);
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button
          intent="secondary"
          className="gap-1"
          size="sm"
          data-testid="chart-view-settings-add-filters-button"
        >
          <Button.Icon icon={hasFilters ? EditIcon : AddIcon} />
          {hasFilters
            ? t(
                'pages.element_settings.report.categories.data_display.filters.edit_filters_button_label'
              )
            : t(
                'pages.element_settings.report.categories.data_display.filters.add_filters_button_label'
              )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <ReportFiltersDialogContent
          sourceObject={sourceObject}
          filters={filters}
          onFormSubmit={handleReportFiltersSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
