import { Trans } from 'react-i18next';

import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import { ViewSourceBanner } from '@/pages/pages/settings-panel/view-settings/ViewSourceBanner';

export function ChartSourceTableInfo() {
  const { chartSourceObject } = useActiveChartContext();

  return (
    <ViewSourceBanner className="mb-6">
      <Trans
        i18nKey="pages.element_settings.report.categories.general.table"
        values={{
          tableName: chartSourceObject.name
        }}
      >
        <ViewSourceBanner.ObjectName objectName={chartSourceObject.name} />
      </Trans>
    </ViewSourceBanner>
  );
}
