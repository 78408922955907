import { useFormContext } from 'react-hook-form';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import { type MultipleChoiceDraggableOption } from '@/components/field-settings/multiple-choice/FormContent';
import { OptionsListItem } from '@/components/field-settings/multiple-choice/OptionsListItem';

type OptionsDraggableListProps = {
  options: MultipleChoiceDraggableOption[];
  setOptions: (options: MultipleChoiceDraggableOption[]) => void;
  isImmutable: boolean;
};

export function OptionsDraggableList({
  options,
  setOptions,
  isImmutable
}: OptionsDraggableListProps) {
  const { setValue, getValues } = useFormContext();
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = options?.findIndex(({ id }) => id === active.id);
      const newIndex = options?.findIndex(({ id }) => id === over.id);
      const reorderedOptions = arrayMove(options, oldIndex, newIndex);
      setOptions(reorderedOptions);
      setValue('format.sorting', 'custom');
    }
  };

  const onChangeInputOptionValue = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const isDefaultValueOption = options[index].option === getValues('format.default');
    if (isDefaultValueOption) {
      setValue('format.default', 'kn-blank');
    }

    const updatedOptions = options.map((o, i) =>
      i === index ? { ...o, option: e.target.value } : o
    );
    setValue('format.sorting', 'custom');
    setOptions(updatedOptions);
  };

  const onDeleteOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    if (!updatedOptions.find(({ option }) => option === getValues('format.default'))) {
      setValue('format.default', updatedOptions[0].option);
    }
    setOptions(updatedOptions);
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext items={options.map(({ id }) => id)}>
        <div className="flex flex-col gap-2" data-testid="options-list">
          {options.map((option, i) => (
            <OptionsListItem
              key={option.id}
              option={option}
              index={i}
              canDelete={options.length > 1 && !isImmutable}
              canEdit={!isImmutable}
              onDeleteOption={() => onDeleteOption(i)}
              onChangeInputOptionValue={(e) => onChangeInputOptionValue(e, i)}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}
