import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { Badge, Button, Divider, Label, RadioGroup, Select, Switch } from '@knack/asterisk-react';

import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { ViewSettingsDefaultFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsDefaultFiltersDialog';
import { ViewSettingsMenuFiltersDialog } from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsMenuFiltersDialog';
import { FiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/common/FiltersGroupCard';
import { FiltersGroupSingleCard } from '@/pages/pages/settings-panel/view-settings/common/FiltersGroupSingleCard';

export interface ViewSettingsFilteringOption {
  filter_type: 'fields' | 'menu' | 'none';
  filter_fields: 'view' | 'object';
  preset_filters: KnackFilter[];
  menu_filters: KnackMenuFilter[];
}
interface ViewSettingsFilteringOptions {
  sourceObject: KnackObject;
  options: ViewSettingsFilteringOption;
  updateViewSchema: (options: Partial<ViewSettingsFilteringOption>) => void;
}

export function ViewSettingsFiltering({
  sourceObject,
  options,
  updateViewSchema
}: ViewSettingsFilteringOptions) {
  const [t] = useTranslation();

  const shouldAllowFiltering = options.filter_type !== 'none';

  return (
    <>
      <div className="mb-2 flex items-center gap-2">
        <Switch
          id="allow-filtering"
          data-testid="allow-filtering-switch"
          checked={shouldAllowFiltering}
          onCheckedChange={(state) => {
            const value = state ? 'fields' : 'none';

            updateViewSchema({
              filter_type: value
            });
          }}
        />
        <Label htmlFor="allow-filtering">
          {t(
            'pages.element_settings.common.categories.data_display.filtering_section.allow_filtering'
          )}
        </Label>
      </div>
      {shouldAllowFiltering && (
        <div>
          <div className="pl-4">
            <RadioGroup
              onValueChange={(value: 'fields' | 'menu') => updateViewSchema({ filter_type: value })}
              value={options.filter_type}
              defaultValue="fields"
            >
              <RadioGroup.Container>
                <RadioGroup.Item value="fields" id="custom-radio" />
                <Label htmlFor="custom-radio">
                  {t(
                    'pages.element_settings.common.categories.data_display.filtering_section.allow_users_to_add_custom_filters'
                  )}
                </Label>
              </RadioGroup.Container>
              <RadioGroup.Container>
                <RadioGroup.Item value="menu" id="menu-radio" />
                <Label htmlFor="menu-radio">
                  {t(
                    'pages.element_settings.common.categories.data_display.filtering_section.enable_preset_filter_links'
                  )}
                </Label>
              </RadioGroup.Container>
            </RadioGroup>
            {options.filter_type === 'menu' ? (
              <div className="my-4">
                <p className="mb-2 text-xs text-emphasis">
                  {t(
                    'pages.element_settings.common.categories.data_display.filtering_section.preset_filters_helper_text'
                  )}
                </p>
                <div className="mb-4">
                  <FiltersGroupSingleCard filtersCriteria={options.menu_filters} />
                </div>
                <div className="flex items-center gap-2">
                  <ViewSettingsMenuFiltersDialog
                    sourceObject={sourceObject}
                    filters={options.menu_filters || []}
                  />
                </div>
              </div>
            ) : (
              <div className="mb-2 mt-4 flex flex-col gap-2">
                <Label>
                  {t(
                    'pages.element_settings.common.categories.data_display.filtering_section.which_fields_can_be_filtered'
                  )}
                </Label>
                <Select
                  value={options.filter_fields}
                  onValueChange={(value: 'view' | 'object') =>
                    updateViewSchema({
                      filter_fields: value
                    })
                  }
                >
                  <Select.Trigger className="w-full" />
                  <Select.Content>
                    <Select.Item value="object">
                      {t('pages.element_settings.all_fields')}
                    </Select.Item>
                    <Select.Item value="view">
                      {t(
                        'pages.element_settings.common.categories.data_display.filtering_section.only_fields_used_by_this_element'
                      )}
                    </Select.Item>
                  </Select.Content>
                </Select>
              </div>
            )}
          </div>
          <BuilderAccordion>
            {options.filter_type === 'fields' && (
              <>
                <Divider className="my-6" />
                <BuilderAccordion.Item
                  isDefaultOpen
                  label={t(
                    'pages.element_settings.common.categories.data_display.filtering_section.set_default_filters'
                  )}
                >
                  {options.preset_filters.length > 0 && (
                    <div className="mb-4">
                      <FiltersGroupsCard
                        filtersCriteria={options.preset_filters}
                        shouldHideEmptyDescription
                      />
                    </div>
                  )}
                  <ViewSettingsDefaultFiltersDialog
                    filters={options.preset_filters}
                    sourceObject={sourceObject}
                  />
                </BuilderAccordion.Item>
              </>
            )}
            <BuilderAccordion.Item
              isDefaultOpen
              label={t(
                'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections'
              )}
            >
              <div className="mb-2">
                {t(
                  'pages.element_settings.common.categories.data_display.filtering_section.users_can_filter_by_any_connection'
                )}
              </div>
              <div className="flex flex-wrap items-center gap-2">
                <Button intent="secondary" disabled>
                  <PlusIcon size={16} className="mr-1" />
                  {t(
                    'pages.element_settings.common.categories.data_display.filtering_section.restricted_connections'
                  )}
                </Button>
                <Badge className="text-subtle">{t('keywords.coming_soon')}</Badge>
              </div>
            </BuilderAccordion.Item>
          </BuilderAccordion>
        </div>
      )}
    </>
  );
}
