import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import {
  KNACK_CRITERIA_VALUE_TYPES,
  type KnackCriteriaWithValueType
} from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { useCriteriaHelpers, type CriteriaType } from '@/hooks/helpers/useCriteriaHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { FieldCriteriaValueInput } from '@/components/inputs/FieldCriteriaValueInput';

interface CriteriaFormProps {
  sourceObject: KnackObject;
  criteriaType: CriteriaType;
  defaultCriteria?: KnackCriteriaWithValueType;
  isSingleCriteriaForm?: boolean;
  shouldHaveContent?: boolean;
  className?: string;
}

export function CriteriaForm({
  sourceObject,
  criteriaType,
  defaultCriteria,
  isSingleCriteriaForm = false,
  shouldHaveContent = false,
  className
}: CriteriaFormProps) {
  const [t] = useTranslation();

  const {
    getCriteriaFieldOperators,
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator
  } = useCriteriaHelpers();

  const {
    control,
    getValues,
    watch,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<{ criteria: KnackCriteriaWithValueType[] }>();

  const {
    fields: criteriaFormFields,
    append: appendCriteria,
    update: updateCriteria,
    remove: removeCriteria
  } = useFieldArray({
    name: 'criteria',
    control
  });

  const watchFieldArray = watch('criteria');

  const controlledCriteriaFormFields = criteriaFormFields.map((field, index) => ({
    ...field,
    ...watchFieldArray[index]
  }));

  const getDefaultRuleCriteria = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    if (sourceObject.fields.length === 0) {
      return undefined;
    }

    const firstFieldInObject = sourceObject.fields[0];

    const defaultRuleCriteria: KnackCriteriaWithValueType = {
      field: firstFieldInObject.key,
      operator: getDefaultCriteriaOperator(firstFieldInObject, criteriaType),
      value: getDefaultCriteriaValue(firstFieldInObject)
    };

    return defaultRuleCriteria;
  };

  const getCriteriaValueTypeToRender = (criteria: KnackCriteriaWithValueType) => {
    const selectedField = sourceObject.fields.find((field) => field.key === criteria.field);

    if (!selectedField || shouldHideValueBasedOnOperator(criteria.operator)) {
      return undefined;
    }

    if (criteria.value_type && criteria.value_type === 'field') {
      return 'field';
    }

    return 'custom';
  };

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newFieldKey
  }: {
    criteriaIndex: number;
    newFieldKey: KnackFieldKey;
  }) => {
    let previousField: KnackField | null = null;
    let newField: KnackField | null = null;

    const criteriaToEdit = getValues(`criteria.${criteriaIndex}`);

    sourceObject.fields.forEach((field) => {
      if (field.key === criteriaToEdit.field) {
        previousField = field;
      } else if (field.key === newFieldKey) {
        newField = field;
      }
    });

    if (!newField) {
      return;
    }

    const newCriteria = {
      ...criteriaToEdit,
      field: newFieldKey,
      operator: shouldResetCriteriaOperator(newField, criteriaToEdit.operator, criteriaType)
        ? getDefaultCriteriaOperator(newField, criteriaType)
        : criteriaToEdit.operator,
      value:
        !previousField || shouldResetCriteriaValue(newField, previousField)
          ? getDefaultCriteriaValue(newField)
          : criteriaToEdit.value
    };

    updateCriteria(criteriaIndex, newCriteria);

    clearFormErrors();
  };

  const onCriteriaOperatorChange = ({ criteriaFormField, criteriaFormFieldIndex, newOperator }) => {
    // If the operator selected is a date/time range operator, we want to ensure that the value type is `custom`
    const shouldSetValueTypeToCustom =
      isDateTimeRangeOperator(newOperator) && criteriaFormField.value_type === 'field';

    updateCriteria(criteriaFormFieldIndex, {
      ...getValues(`criteria.${criteriaFormFieldIndex}`),
      operator: newOperator,

      ...(shouldSetValueTypeToCustom && {
        value_type: 'custom'
      })
    });

    clearFormErrors();
  };

  return (
    <Form.Section className={cn('p-2', className)}>
      {controlledCriteriaFormFields.length === 0 && criteriaType !== 'filter' && (
        <p className="text-subtle">{t('components.rules.run_every_time')}</p>
      )}

      {controlledCriteriaFormFields.map((criteriaFormField, criteriaFormFieldIndex) => {
        const criteriaValueTypeToRender = getCriteriaValueTypeToRender(criteriaFormField);
        const criteriaField = sourceObject.fields.find((f) => f.key === criteriaFormField.field);
        const criteriaFieldOperators = criteriaField
          ? getCriteriaFieldOperators(criteriaField, criteriaType)
          : [];

        return (
          <Fragment key={criteriaFormField.id}>
            {criteriaFormFieldIndex > 0 && (
              <p className="my-3 text-xs font-medium">{t('components.rules.and_uppercase')}</p>
            )}

            <div className="flex">
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <div className="max-w-1/2 -m-1 flex-1 overflow-hidden p-1">
                    <Controller
                      name={`criteria.${criteriaFormFieldIndex}.field`}
                      render={({ field: { value: fieldKey } }) => (
                        <Select
                          value={criteriaField ? fieldKey : undefined}
                          onValueChange={(newFieldKey: KnackFieldKey) => {
                            onCriteriaFieldChange({
                              criteriaIndex: criteriaFormFieldIndex,
                              newFieldKey
                            });
                          }}
                        >
                          <Select.Trigger
                            data-testid="criteria-field-select"
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.criteria?.[criteriaFormFieldIndex]?.field
                            })}
                          />
                          <Select.Content className="min-w-[230px]">
                            {sourceObject.fields.map((field) => (
                              <Select.Item key={field.key} value={field.key}>
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={field.type}
                                  />
                                  <span className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                    {field.name}
                                  </span>
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                    <FormErrorMessage
                      name={`criteria.${criteriaFormFieldIndex}.field`}
                      errors={errors}
                      className="mt-1"
                    />
                  </div>

                  <div className="max-w-1/2 -m-1 flex-1 overflow-hidden p-1">
                    <Controller
                      name={`criteria.${criteriaFormFieldIndex}.operator`}
                      render={({ field: { value: operator } }) => (
                        <Select
                          disabled={!criteriaField}
                          value={operator}
                          onValueChange={(newOperator: KnackOperator) => {
                            onCriteriaOperatorChange({
                              criteriaFormField,
                              criteriaFormFieldIndex,
                              newOperator
                            });
                          }}
                        >
                          <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                          <Select.Content>
                            {criteriaFieldOperators.map((fieldOperator) => (
                              <Select.Item key={fieldOperator} value={fieldOperator}>
                                {t(`operators.${snakeCase(fieldOperator)}`)}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                </div>

                {criteriaFormField.value_type && criteriaValueTypeToRender && (
                  <div className="mt-2">
                    <Controller
                      control={control}
                      name={`criteria.${criteriaFormFieldIndex}.value_type`}
                      render={({ field: { value: valueType } }) => (
                        <Select
                          value={valueType}
                          onValueChange={(newValueType) => {
                            updateCriteria(criteriaFormFieldIndex, {
                              ...getValues(`criteria.${criteriaFormFieldIndex}`),
                              value_type: newValueType as KnackCriteriaWithValueType['value_type']
                            });
                            clearFormErrors();
                          }}
                        >
                          <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                          <Select.Content>
                            {KNACK_CRITERIA_VALUE_TYPES.map((criteriaValueType) => {
                              // If the current operator is a date time range operator, we don't allow the user to select a field as the value for comparison
                              const isDisabled =
                                criteriaValueType === 'field' &&
                                isDateTimeRangeOperator(criteriaFormField.operator);

                              return (
                                <Select.Item
                                  key={criteriaValueType}
                                  value={criteriaValueType}
                                  disabled={isDisabled}
                                >
                                  {t(
                                    `components.rules.criteria_value_type.${snakeCase(criteriaValueType)}`
                                  )}
                                </Select.Item>
                              );
                            })}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                )}

                {criteriaValueTypeToRender === 'custom' && (
                  <div className="mt-2">
                    <FieldCriteriaValueInput
                      fieldKey={criteriaFormField.field}
                      selectedOperator={criteriaFormField.operator}
                      index={criteriaFormFieldIndex}
                      sourceObject={sourceObject}
                    />
                  </div>
                )}

                {criteriaValueTypeToRender === 'field' && (
                  <div className="mt-2">
                    <Controller
                      name={`criteria.${criteriaFormFieldIndex}.value_field`}
                      render={({ field: { value: fieldKey, onChange } }) => (
                        <Select value={fieldKey} onValueChange={onChange}>
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.criteria?.[criteriaFormFieldIndex]?.value_field
                            })}
                          />
                          <Select.Content>
                            {sourceObject.fields.map((field) => (
                              <Select.Item key={field.key} value={field.key}>
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={field.type}
                                  />
                                  {field.name}
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                    <FormErrorMessage
                      name={`criteria.${criteriaFormFieldIndex}.value_field`}
                      errors={errors}
                      className="mt-1"
                    />
                  </div>
                )}
              </div>
              {!isSingleCriteriaForm &&
                (!shouldHaveContent ||
                  (shouldHaveContent && controlledCriteriaFormFields.length > 1)) && (
                  <Button
                    data-testid="remove-criteria-button"
                    intent="minimal"
                    aria-label={t('components.rules.delete_condition')}
                    size="xs"
                    className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                    onClick={() => removeCriteria(criteriaFormFieldIndex)}
                  >
                    <RemoveIcon size={16} />
                  </Button>
                )}
            </div>
          </Fragment>
        );
      })}
      {!isSingleCriteriaForm && (
        <Button
          intent="secondary"
          aria-label={t('components.rules.add_condition')}
          className="mt-3"
          onClick={() => {
            const defaultCriteriaToAdd = defaultCriteria || getDefaultRuleCriteria();
            if (defaultCriteriaToAdd) {
              appendCriteria(defaultCriteriaToAdd);
            }
          }}
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {criteriaType === 'filter'
            ? t('components.rules.filter')
            : t('components.rules.condition')}
        </Button>
      )}
    </Form.Section>
  );
}
