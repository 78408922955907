import { Trans, useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner } from '@knack/asterisk-react';

import { LearnMoreLink } from '@/components/LearnMoreLink';

export function DeleteOptionBanner() {
  const [t] = useTranslation();

  return (
    <Banner
      intent="warning"
      icon={ExclamationIcon}
      type="inline"
      title={t(
        'components.data_table.attributes.field_settings.multiple_choice.warning_delete_option.title'
      )}
    >
      <Banner.Message className="text-justify font-normal">
        <Trans i18nKey="components.data_table.attributes.field_settings.multiple_choice.warning_delete_option.text">
          <span className="font-semibold">options</span>
        </Trans>
        <br />
        <Trans
          i18nKey="components.data_table.attributes.field_settings.multiple_choice.warning_delete_option.learn_more"
          components={[
            <LearnMoreLink
              key="0"
              href="https://learn.knack.com/apps/understanding-fields#editing-multiple-choice"
              className="text-inherit"
            />
          ]}
        />
      </Banner.Message>
    </Banner>
  );
}
