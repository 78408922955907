import { createContext, useCallback, useContext, useMemo } from 'react';

import { type KnackObject } from '@/types/schema/KnackObject';
import { type ReportView, type ReportViewChart } from '@/types/schema/views/ReportView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

type ActiveChartContextState = {
  chart: ReportViewChart;
  chartSourceObject: KnackObject;
  updateChart: (changes: Partial<ReportViewChart>) => void;
} | null;

type ActiveChartContextProviderProps = {
  chart: ReportViewChart;
  children: React.ReactNode;
};

const ActiveChartContext = createContext<ActiveChartContextState>(null);

export function ActiveChartContextProvider({ chart, children }: ActiveChartContextProviderProps) {
  const { getObjectByKey } = useObjectHelpers();
  const { view } = useActiveViewContext<ReportView>();
  const updateViewSchema = useUpdateView<ReportView>();

  const chartSourceObject = useMemo(
    () => getObjectByKey(chart.source.object),
    [getObjectByKey, chart.source.object]
  );

  if (!chartSourceObject) {
    throw new Error('Chart source object not found');
  }

  const updateChart = useCallback(
    (changes: Partial<ReportViewChart>) => {
      updateViewSchema({
        rows: [
          {
            ...view.rows[0],
            reports: view.rows[0].reports.map((chartInView) => {
              if (chartInView.id === chart.id) {
                const updatedChart: ReportViewChart = {
                  ...chartInView,
                  ...changes
                };
                return updatedChart;
              }
              return chartInView;
            })
          }
        ]
      });
    },
    [updateViewSchema, chart.id, view.rows]
  );

  const value = useMemo(
    () => ({
      chart,
      chartSourceObject,
      updateChart
    }),
    [chart, chartSourceObject, updateChart]
  );

  return <ActiveChartContext.Provider value={value}>{children}</ActiveChartContext.Provider>;
}

export const useActiveChartContext = () => {
  const context = useContext(ActiveChartContext);

  if (!context) {
    throw new Error('useActiveChartContext must be used within an ActiveChartContextProvider');
  }

  return context;
};
