import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPencil as EditIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFilter, type KnackMenuFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { cn } from '@/utils/tailwind';
import { CriteriaForm } from '@/components/CriteriaForm';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';

export type DefaultFiltersFormData = {
  criteria: KnackMenuFilter[];
};

function ViewSettingsDefaultFiltersDialogContent({
  filters,
  sourceObject,
  onFormSubmit
}: {
  filters: KnackFilter[];
  sourceObject: KnackObject;
  onFormSubmit: (data: DefaultFiltersFormData) => void;
}) {
  const [t] = useTranslation();
  const { validateCriteriaValues } = useCriteriaHelpers();

  const defaultFiltersRulesSchema = z
    .object({
      criteria: z.custom<KnackCriteria[]>()
    })
    .superRefine((data, context) => {
      const criteriaValueErrors = validateCriteriaValues(data.criteria, sourceObject.fields);

      if (criteriaValueErrors.length) {
        criteriaValueErrors.forEach((error) => {
          context.addIssue({
            path: error.path,
            message: t(error.message || 'errors.value_required'),
            code: 'custom'
          });
        });
      }
    });

  const form = useForm<DefaultFiltersFormData>({
    resolver: zodResolver(defaultFiltersRulesSchema),
    defaultValues: {
      criteria: filters
    }
  });

  const hasFilters = form.getValues('criteria').length > 0;

  return (
    <FormProvider {...form}>
      <form className="w-full" onSubmit={form.handleSubmit(onFormSubmit)}>
        <Dialog.MainContent>
          <Dialog.Header>
            <Dialog.Title>
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.default_filters'
              )}
            </Dialog.Title>
            <Dialog.Description className="text-xs text-subtle">
              {t(
                'pages.element_settings.common.categories.data_display.filtering_section.default_filters_description'
              )}
            </Dialog.Description>
          </Dialog.Header>
          {hasFilters && (
            <p className="my-4">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
              )}
            </p>
          )}
          <div
            className={cn({
              'rounded-lg bg-subtle': hasFilters
            })}
          >
            <CriteriaForm criteriaType="filter" sourceObject={sourceObject} />
          </div>
        </Dialog.MainContent>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <Button intent="minimal">{t('actions.cancel')}</Button>
          </Dialog.Close>
          <Button type="submit">{t('actions.apply')}</Button>
        </Dialog.Footer>
      </form>
    </FormProvider>
  );
}

export function ViewSettingsDefaultFiltersDialog({ sourceObject, filters }) {
  const [t] = useTranslation();
  const { view } = useActiveViewContext();
  const updateViewSchema = useUpdateView();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDefaultFiltersSubmit = (data: DefaultFiltersFormData) => {
    if (view.type === 'map') {
      updateViewSchema({
        details: {
          ...view.details,
          allow_preset_filters: true,
          preset_filters: data.criteria
        }
      });
      setIsDialogOpen(false);
      return;
    }
    updateViewSchema({
      allow_preset_filters: true,
      preset_filters: data.criteria
    });
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button intent="secondary">
          {filters.length > 0 ? (
            <EditIcon size={16} className="mr-1" />
          ) : (
            <PlusIcon size={16} className="mr-1" />
          )}
          {t(
            'pages.element_settings.common.categories.data_display.filtering_section.default_filters'
          )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <ViewSettingsDefaultFiltersDialogContent
          onFormSubmit={handleDefaultFiltersSubmit}
          filters={filters}
          sourceObject={sourceObject}
        />
      </Dialog.Content>
    </Dialog>
  );
}
