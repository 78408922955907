import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Banner, Button, Dialog, Divider, Form } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackField } from '@/types/schema/KnackField';
import {
  type FormViewDisplayRule,
  type FormViewDisplayRuleAction
} from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { FormDisplayRuleActionsInfoButton } from './FormDisplayRuleActionsInfoButton';
import { FormDisplayRuleFormActions } from './FormDisplayRuleFormActions';
import { FormDisplayRuleFormCriteria } from './FormDisplayRuleFormCriteria';

function findDuplicateRuleActions(ruleActions: FormViewDisplayRuleAction[]) {
  const actionsSeen = new Set<string>();
  const duplicateRuleActions: FormViewDisplayRuleAction[] = [];

  ruleActions.forEach((item) => {
    const identifier = `${item.field}-${item.action}`;
    if (actionsSeen.has(identifier)) {
      duplicateRuleActions.push(item);
    } else {
      actionsSeen.add(identifier);
    }
  });

  return duplicateRuleActions;
}

interface FormDisplayRuleFormDialogProps {
  displayRule: FormViewDisplayRule;
  availableViewFields: KnackField[];
  formIntent?: 'edit' | 'add';
  onRuleSave: (updatedDisplayRule: FormViewDisplayRule) => void;
  onOpenChange: (isOpen: boolean) => void;
}

export function FormDisplayRuleFormDialog({
  displayRule,
  availableViewFields,
  formIntent = 'edit',
  onRuleSave,
  onOpenChange
}: FormDisplayRuleFormDialogProps) {
  const [t] = useTranslation();

  const { isCriteriaValueEmpty } = useCriteriaHelpers();

  const displayRuleFormSchema = z.custom<FormViewDisplayRule>().superRefine((data, ctx) => {
    // Validate criteria values
    data.criteria.forEach((criteria, criteriaIndex) => {
      const isFieldMissingFromView = !availableViewFields.some(
        (field) => field.key === criteria.field
      );

      // Only show a validation error on the field if the selected field is missing from the view
      if (isFieldMissingFromView) {
        ctx.addIssue({
          path: [`criteria.${criteriaIndex}.field`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      const isValueRequired =
        !shouldHideValueBasedOnOperator(criteria.operator) &&
        !isDateTimeRangeOperator(criteria.operator);

      if (!isValueRequired) {
        return;
      }

      const selectedField = availableViewFields.find((field) => field.key === criteria.field);

      if (!selectedField) {
        return;
      }

      const isValueEmpty = isCriteriaValueEmpty(criteria.value, selectedField);

      // Only show a validation error on the value if the operator requires a value, and the value is missing
      if (isValueEmpty) {
        ctx.addIssue({
          path: [`criteria.${criteriaIndex}.value`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }
    });

    // Validate action values
    data.actions.forEach((action, actionIndex) => {
      const isFieldMissingFromView = !availableViewFields.some(
        (field) => field.key === action.field
      );

      // Only show a validation error on the field if the selected field is missing from the view
      if (isFieldMissingFromView) {
        ctx.addIssue({
          path: [`actions.${actionIndex}.field`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      // Only show a validation error on the value if the action type is 'rename label', and the value is missing
      if (action.action === 'label' && action.value === '') {
        ctx.addIssue({
          path: [`actions.${actionIndex}.value`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }
    });

    // Loop through the rule actions and check if there are any duplicates
    const duplicateActions = findDuplicateRuleActions(data.actions as FormViewDisplayRuleAction[]);
    if (duplicateActions.length) {
      ctx.addIssue({
        path: ['actions'],
        message: t('components.display_rule_card.errors.duplicate_actions'),
        code: 'custom'
      });
    }
  });

  const form = useForm<FormViewDisplayRule>({
    resolver: zodResolver(displayRuleFormSchema),
    defaultValues: displayRule
  });

  const { handleSubmit } = form;

  const onSubmit = (updatedDisplayRule: FormViewDisplayRule) => {
    onRuleSave(updatedDisplayRule);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {formIntent === 'edit'
                    ? t('components.display_rule_card.edit_modal.title')
                    : t('components.display_rule_card.add_modal.title')}
                </Dialog.Title>
                <Dialog.Description className="sr-only">
                  {formIntent === 'edit'
                    ? t('components.display_rule_card.edit_modal.description')
                    : t('components.display_rule_card.add_modal.description')}
                </Dialog.Description>
              </Dialog.Header>

              <div className="mt-6">
                <h3 className="mb-2 font-medium">{t('components.rules.when_extended')}</h3>
                {formIntent === 'add' && availableViewFields.length === 0 ? (
                  <Form.Section className="rounded-lg bg-subtle p-2">
                    <p className="text-subtle">
                      {t('components.display_rule_card.no_eligible_field_inputs')}
                    </p>
                  </Form.Section>
                ) : (
                  <FormDisplayRuleFormCriteria availableViewFields={availableViewFields} />
                )}

                <Divider className="my-4 *:bg-action" />

                <h3 className="mb-2 flex items-center font-medium">
                  <span className="mr-1">{t('components.rules.then_extended')}</span>
                  <FormDisplayRuleActionsInfoButton />
                </h3>

                {formIntent === 'add' && availableViewFields.length === 0 ? (
                  <Form.Section className="rounded-lg bg-subtle p-2">
                    <p className="text-subtle">
                      {t('components.display_rule_card.no_eligible_field_inputs')}
                    </p>
                  </Form.Section>
                ) : (
                  <FormDisplayRuleFormActions availableViewFields={availableViewFields} />
                )}

                {form.formState.errors.actions?.root && (
                  <Banner intent="warning" className="mt-2">
                    {form.formState.errors.actions?.root.message}
                  </Banner>
                )}
              </div>
            </Dialog.MainContent>

            <Dialog.Footer>
              <Button intent="minimal" onClick={() => onOpenChange(false)}>
                {t('actions.cancel')}
              </Button>
              <Button type="submit">
                {formIntent === 'edit'
                  ? t('components.display_rule_card.edit_modal.submit_button')
                  : t('components.display_rule_card.add_modal.submit_button')}
              </Button>
            </Dialog.Footer>
          </form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}
