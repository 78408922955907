import { nanoid } from 'nanoid';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type DetailsView,
  type DetailsViewDisplayRule,
  type DetailsViewDisplayRuleAction
} from '@/types/schema/views/DetailsView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useViewHelpers } from '@/hooks/helpers/useViewHelpers';

export function useDisplayRulesHelpers({
  view,
  sourceObject
}: {
  view: DetailsView;
  sourceObject: KnackObject;
}) {
  const { getViewFields } = useViewHelpers();
  const { hasRoleObjects } = useObjectHelpers();
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const getAvailableViewFieldsForDisplayRules = () =>
    getViewFields({
      view,
      sourceObject,

      // Ignore the user roles fields if no user roles exist in the application
      fieldTypesToIgnore: !hasRoleObjects() ? ['user_roles'] : undefined
    });

  // TODO: FE-3702 Implement invalid display rules indicator
  const getInvalidDisplayRules = () => [] as DetailsViewDisplayRule[];

  const getDefaultDisplayRuleCriteria = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = sourceObject.fields[0];
    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleCriteria: KnackCriteria = {
      field: firstAvailableFieldInView.key,
      operator: getDefaultCriteriaOperator(firstAvailableFieldInView, 'display-rule'),
      value: getDefaultCriteriaValue(firstAvailableFieldInView)
    };

    return defaultDisplayRuleCriteria;
  };

  const getDefaultDisplayRuleAction = (): DetailsViewDisplayRuleAction | undefined => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = sourceObject.fields[0];

    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleAction: DetailsViewDisplayRuleAction = {
      field: firstAvailableFieldInView.key,
      action: 'show',
      value: ''
    };

    return defaultDisplayRuleAction as DetailsViewDisplayRuleAction;
  };

  const getDefaultDisplayRule = () => {
    const defaultDisplayRuleCriteria = getDefaultDisplayRuleCriteria();
    const defaultDisplayRuleAction = getDefaultDisplayRuleAction();

    const defaultDisplayRule: DetailsViewDisplayRule = {
      key: `display_${nanoid(10)}` as KnackFieldKey,
      criteria: defaultDisplayRuleCriteria ? [defaultDisplayRuleCriteria] : [],
      actions: defaultDisplayRuleAction ? [defaultDisplayRuleAction] : []
    };

    return defaultDisplayRule;
  };

  return {
    getAvailableViewFieldsForDisplayRules,
    getDefaultDisplayRule,
    getDefaultDisplayRuleCriteria,
    getDefaultDisplayRuleAction,
    getInvalidDisplayRules
  };
}
