import { useState } from 'react';
import {
  HiViewColumns as ColumnsIcon,
  HiTableCells as FieldsIcon,
  HiCog6Tooth as GeneralSettingsIcon
} from 'react-icons/hi2';
import { t } from 'i18next';

import { CollapsiblePanel } from '@/components/layout/CollapsiblePanel';
import { ViewWithDetailsActionsSettingsCategory } from '@/pages/pages/settings-panel/view-settings/common/details-actions/ViewWithDetailsActionsSettingsCategory';
import { MapDataDisplaySettings } from '@/pages/pages/settings-panel/view-settings/map/MapDataDisplaySettings';
import { MapGeneralSettingsCategory } from '@/pages/pages/settings-panel/view-settings/map/MapGeneralSettingsCategory';
import { MapInputEditModal } from '@/pages/pages/settings-panel/view-settings/map/MapInputEditModal';
import { MapMapSettingsCategory } from '@/pages/pages/settings-panel/view-settings/map/MapMapSettingsCategory';
import {
  ViewSettingsCategoryList,
  type ViewSettingsCategoryItem
} from '@/pages/pages/settings-panel/view-settings/ViewSettingsCategoryList';

type MapSettingsCategoryId = 'general-settings' | 'map-settings' | 'data-display' | 'actions';
type MapSettingsCategoryItem = ViewSettingsCategoryItem<MapSettingsCategoryId>;

const MAPS_SETTINGS_CATEGORIES: MapSettingsCategoryItem[] = [
  {
    id: 'general-settings',
    name: t('pages.element_settings.common.categories.general_settings.name'),
    description: t('pages.element_settings.map.categories.general_settings.description'),
    icon: GeneralSettingsIcon
  },
  {
    id: 'map-settings',
    name: t('pages.element_settings.map.categories.map_settings.name'),
    description: t('pages.element_settings.map.categories.map_settings.description'),
    icon: FieldsIcon
  },
  {
    id: 'data-display',
    name: t('pages.element_settings.common.categories.data_display.name'),
    description: t('pages.element_settings.map.categories.data_display.description'),
    icon: FieldsIcon
  },
  {
    id: 'actions',
    name: t('pages.element_settings.common.categories.actions.name'),
    description: t('pages.element_settings.map.categories.actions.description'),
    icon: ColumnsIcon
  }
];

function getPanelTitle(
  activeCategoryId: MapSettingsCategoryId | null,
  categories: MapSettingsCategoryItem[]
) {
  const activeCategory = activeCategoryId
    ? categories.find((category) => category.id === activeCategoryId)
    : null;

  return activeCategory ? activeCategory.name : t('pages.element_settings.map.heading');
}

export function MapSettings() {
  const [activeCategoryId, setActiveCategoryId] = useState<MapSettingsCategoryId | null>(null);
  const panelTitle = getPanelTitle(activeCategoryId, MAPS_SETTINGS_CATEGORIES);

  return (
    <>
      <CollapsiblePanel.Content
        title={panelTitle}
        onBack={activeCategoryId ? () => setActiveCategoryId(null) : undefined}
        shouldHideDivider={activeCategoryId === 'data-display'}
      >
        {activeCategoryId ? (
          <>
            {activeCategoryId === 'general-settings' && <MapGeneralSettingsCategory />}
            {activeCategoryId === 'map-settings' && <MapMapSettingsCategory />}
            {activeCategoryId === 'data-display' && <MapDataDisplaySettings />}
            {activeCategoryId === 'actions' && <ViewWithDetailsActionsSettingsCategory />}
          </>
        ) : (
          <ViewSettingsCategoryList
            categories={MAPS_SETTINGS_CATEGORIES}
            onCategorySelect={(selectedCategoryId: string) => {
              setActiveCategoryId(selectedCategoryId as MapSettingsCategoryId);
            }}
          />
        )}
      </CollapsiblePanel.Content>
      <MapInputEditModal />
    </>
  );
}
