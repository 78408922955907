import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Input, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackField, type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { type FormView, type FormViewDisplayRule } from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { ConnectionFieldInput } from '@/components/filters/ConnectionFieldInput';
import { MultipleChoiceFieldInput } from '@/components/filters/MultipleChoiceFieldInput';
import { DateTimeCriteriaInput } from '@/components/inputs/date-time/DateTimeCriteriaInput';
import { DateTimeRangeInput } from '@/components/inputs/date-time/DateTimeRangeInput';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useFormDisplayRulesHelpers } from './useFormDisplayRulesHelpers';

interface FormDisplayRuleFormCriteriaProps {
  availableViewFields: KnackField[];
}

export function FormDisplayRuleFormCriteria({
  availableViewFields
}: FormDisplayRuleFormCriteriaProps) {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<FormView>();
  const { getDefaultDisplayRuleCriteria } = useFormDisplayRulesHelpers(view, sourceObject);
  const {
    getCriteriaFieldOperators,
    getDefaultCriteriaValue,
    getDefaultCriteriaOperator,
    shouldResetCriteriaValue,
    shouldResetCriteriaOperator
  } = useCriteriaHelpers();

  const {
    control,
    register,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<FormViewDisplayRule>();

  const {
    fields: criteriaFormFields,
    append: appendCriteria,
    remove: removeCriteria,
    update: updateCriteria
  } = useFieldArray({
    name: 'criteria',
    control
  });

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newFieldKey
  }: {
    criteriaIndex: number;
    newFieldKey: KnackFieldKey;
  }) => {
    const criteriaToEdit = getValues(`criteria.${criteriaIndex}`);
    const previousField = availableViewFields.find((field) => field.key === criteriaToEdit.field);
    const newField = availableViewFields.find((field) => field.key === newFieldKey);

    if (!newField) {
      return;
    }

    const shouldResetValue = previousField
      ? shouldResetCriteriaValue(newField, previousField)
      : true;

    updateCriteria(criteriaIndex, {
      field: newFieldKey,
      operator: shouldResetCriteriaOperator(newField, criteriaToEdit.operator, 'display-rule')
        ? getDefaultCriteriaOperator(newField, 'display-rule')
        : criteriaToEdit.operator,
      value: shouldResetValue ? getDefaultCriteriaValue(newField) : criteriaToEdit.value
    });

    clearFormErrors();
  };

  const renderFieldInput = (
    fieldKey: KnackFieldKey,
    selectedOperator: KnackCriteria['operator'],
    index: number,
    isDisabled: boolean
  ) => {
    const selectedField = sourceObject.fields.find((field) => field.key === fieldKey);

    if (!selectedField || shouldHideValueBasedOnOperator(selectedOperator)) {
      return null;
    }

    if (selectedField.type === 'connection') {
      return (
        <ConnectionFieldInput<FormViewDisplayRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
          isDisabled={isDisabled}
        />
      );
    }

    if (selectedField.type === 'date_time') {
      if (isDateTimeRangeOperator(selectedOperator)) {
        return (
          <DateTimeRangeInput<FormViewDisplayRule>
            rangeTypeFormFieldName={`criteria.${index}.type`}
            rangeValueFormFieldName={`criteria.${index}.range`}
            operator={selectedOperator}
          />
        );
      }
      return (
        <DateTimeCriteriaInput<FormViewDisplayRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
        />
      );
    }

    if (selectedField.type === 'multiple_choice') {
      return (
        <MultipleChoiceFieldInput<FormViewDisplayRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
          isDisabled={isDisabled}
        />
      );
    }

    if (selectedField.type === 'boolean') {
      return (
        <BooleanFieldInput<FormViewDisplayRule>
          field={selectedField}
          formFieldName={`criteria.${index}.value`}
          isDisabled={isDisabled}
        />
      );
    }

    return (
      <Input
        intent={errors?.criteria?.[index]?.value ? 'destructive' : undefined}
        placeholder={t('actions.enter_value')}
        disabled={isDisabled}
        {...register(`criteria.${index}.value`)}
      />
    );
  };

  return (
    <Form.Section className="rounded-lg bg-subtle p-2">
      {criteriaFormFields.length === 0 && (
        <p className="text-subtle">{t('components.rules.no_conditions')}</p>
      )}

      {criteriaFormFields.map((criteriaFormField, criteriaFormFieldIndex) => {
        const criteriaField = availableViewFields.find(
          (field) => field.key === criteriaFormField.field
        );
        const criteriaFieldOperators = criteriaField
          ? getCriteriaFieldOperators(criteriaField, 'display-rule')
          : [];

        const fieldValueInput = renderFieldInput(
          criteriaFormField.field,
          criteriaFormField.operator,
          criteriaFormFieldIndex,
          !criteriaField
        );

        return (
          <Fragment key={criteriaFormField.id}>
            {criteriaFormFieldIndex > 0 && (
              <p className="my-3 text-xs font-medium">{t('components.rules.and_uppercase')}</p>
            )}

            <div className="flex">
              <div className="-m-1 flex-1 overflow-hidden p-1">
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={`criteria.${criteriaFormFieldIndex}.field`}
                      render={({ field: { value: fieldKey } }) => (
                        <Select
                          value={criteriaField ? fieldKey : undefined}
                          onValueChange={(newFieldKey: KnackFieldKey) => {
                            onCriteriaFieldChange({
                              criteriaIndex: criteriaFormFieldIndex,
                              newFieldKey
                            });
                          }}
                          disabled={availableViewFields.length === 0}
                        >
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.criteria?.[criteriaFormFieldIndex]?.field
                            })}
                          />
                          <Select.Content>
                            {availableViewFields.map((field) => (
                              <Select.Item key={field.key} value={field.key}>
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={field.type}
                                  />
                                  {field.name}
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />

                    {errors?.criteria?.[criteriaFormFieldIndex]?.field && (
                      <p className="mt-1 text-destructive">
                        {errors.criteria[criteriaFormFieldIndex]?.field?.message}
                      </p>
                    )}
                  </div>
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={`criteria.${criteriaFormFieldIndex}.operator`}
                      render={({ field: { value: operator } }) => (
                        <Select
                          disabled={!criteriaField}
                          value={operator}
                          onValueChange={(newOperator: KnackOperator) => {
                            updateCriteria(criteriaFormFieldIndex, {
                              ...getValues(`criteria.${criteriaFormFieldIndex}`),
                              operator: newOperator
                            });
                            clearFormErrors();
                          }}
                        >
                          <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                          <Select.Content>
                            {criteriaFieldOperators.map((fieldOperator) => (
                              <Select.Item key={fieldOperator} value={fieldOperator}>
                                {t(`operators.${snakeCase(fieldOperator)}`)}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                </div>
                {fieldValueInput && (
                  <div className="mt-2">
                    {fieldValueInput}
                    {errors?.criteria?.[criteriaFormFieldIndex]?.value && (
                      <p className="mt-1 text-destructive">
                        {errors.criteria[criteriaFormFieldIndex]?.value?.message}
                      </p>
                    )}
                  </div>
                )}
              </div>

              {criteriaFormFields.length > 1 && (
                <Button
                  intent="minimal"
                  aria-label={t('components.rules.delete_condition')}
                  size="xs"
                  className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                  onClick={() => removeCriteria(criteriaFormFieldIndex)}
                >
                  <RemoveIcon size={16} />
                </Button>
              )}
            </div>
          </Fragment>
        );
      })}

      {availableViewFields.length > 0 && (
        <Button
          intent="secondary"
          aria-label={t('components.rules.add_condition')}
          className="mt-3"
          onClick={() => {
            const defaultCriteria = getDefaultDisplayRuleCriteria();
            if (defaultCriteria) {
              appendCriteria(defaultCriteria);
            }
          }}
        >
          <Button.Icon icon={PlusIcon} position="left" />
          {t('components.rules.condition')}
        </Button>
      )}
    </Form.Section>
  );
}
