import { useMutation, useQueryClient } from '@tanstack/react-query';

import { type KnackApplication } from '@/types/schema/KnackApplication';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackConnection, type KnackObject } from '@/types/schema/KnackObject';
import { queryKeys } from '@/hooks/api/queryKeys';
import { axiosInstance as axios } from '@/utils/axiosConfig';

interface UpdateTableMetadataParams {
  object_key: KnackObject['key'];
  name?: string;
  identifier?: string;
}

interface UpdateTableConnectionsParams {
  object_key: string;
  connections?: {
    inbound: KnackConnection[];
    outbound: KnackConnection[];
  };
}

interface SortTableFieldsParams {
  object_key: string;
  order: KnackFieldKey[];
}

interface CopyTableParams {
  object_key: string;
  fields: string[];
  object_name: string;
  isUser?: boolean;
}

interface CopyFieldsParams extends CopyTableParams {
  target_object_id: string;
}

async function copyTable({ object_key, object_name, fields, isUser = false }: CopyTableParams) {
  const data = { object_name, fields, user_role: isUser };

  const { data: responseData } = await axios.post(`/v1/objects/${object_key}/copy`, data, {
    withCredentials: true
  });
  return responseData;
}

async function copyFields({ object_key, object_name, fields, target_object_id }: CopyFieldsParams) {
  const data = { object_name, fields, target_object_id };

  const { data: responseData } = await axios.post(`/v1/objects/${object_key}/copy_fields`, data, {
    withCredentials: true
  });
  return responseData;
}

async function sortTables(order: string[]) {
  const { data } = await axios.post(
    '/v1/objects/sort',
    { order },
    {
      withCredentials: true
    }
  );
  return data;
}

async function updateTable({ object_key, name, identifier }: UpdateTableMetadataParams) {
  const data = { name, identifier };

  const { data: responseData } = await axios.put(`/v1/objects/${object_key}`, data, {
    withCredentials: true
  });

  return responseData;
}

async function updateTableConnections({ object_key, connections }: UpdateTableConnectionsParams) {
  const data = { connections };

  const { data: responseData } = await axios.put(`/v1/objects/${object_key}`, data, {
    withCredentials: true
  });

  return responseData;
}

async function sortFields({ object_key, order }: SortTableFieldsParams) {
  const { data } = await axios.post(
    `/v1/objects/${object_key}/fields/sort`,
    { order },
    {
      withCredentials: true
    }
  );
  return data;
}

async function deleteTable(object_key: string) {
  await axios.delete(`/v1/objects/${object_key}`, {
    withCredentials: true
  });
}

export function useTableMutation() {
  const queryClient = useQueryClient();

  return {
    sortMutation: useMutation({
      mutationFn: (order: string[]) => sortTables(order),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
      }
    }),
    sortFieldsMutation: useMutation({
      mutationFn: (params: SortTableFieldsParams) => sortFields(params),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
      }
    }),
    updateMutation: useMutation({
      mutationFn: ({ object_key, name, identifier }: UpdateTableMetadataParams) =>
        updateTable({ object_key, name, identifier }),
      onSuccess: (data) => {
        const modifiedObject = data.object as KnackObject;

        void queryClient.setQueriesData<KnackApplication>(
          { queryKey: [queryKeys.application] },
          (prevData) => {
            if (!prevData) return undefined;
            return {
              ...prevData,
              objects: prevData.objects.map((object) => {
                if (object.key === data.object.key) return modifiedObject;
                return object;
              })
            };
          }
        );
      }
    }),
    updateConnectionsMutation: useMutation({
      mutationFn: ({ object_key, connections }: UpdateTableConnectionsParams) =>
        updateTableConnections({ object_key, connections }),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
      }
    }),
    deleteMutation: useMutation({
      mutationFn: (object_key: string) => deleteTable(object_key),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
        void queryClient.invalidateQueries({
          queryKey: [queryKeys.applicationPages]
        });
      }
    }),
    copyTableMutation: useMutation({
      mutationFn: (params: CopyTableParams) => copyTable(params),
      onSuccess: (data) => {
        // Add the new object to the application state
        const newObject = data.object as KnackObject;
        void queryClient.setQueriesData<KnackApplication>(
          { queryKey: [queryKeys.application] },
          (prevData) => {
            if (!prevData) return undefined;
            return {
              ...prevData,
              objects: [...prevData.objects, newObject]
            };
          }
        );
      }
    }),
    copyFieldsMutation: useMutation({
      mutationFn: (params: CopyFieldsParams) => copyFields(params),
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: [queryKeys.application] });
      }
    })
  };
}
