import { useEffect } from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { type BuilderApplication } from '@/types/schema/BuilderApplication';
import { type Session } from '@/types/session';
import { HubspotChatSetup } from '@/HubspotChatSetup';

type IntercomSetupProps = {
  application: BuilderApplication;
  children: React.ReactNode;
  sessionUser: Session['user'];
};

type IntercomInitProps = {
  account: BuilderApplication['account'];
  children: React.ReactNode;
  sessionUser: Session['user'];
};

function IntercomInit({ account, sessionUser, children }: IntercomInitProps) {
  const {
    boot: intercomBoot,
    update: intercomUpdate,
    showMessages: intercomShowMessages,
    shutdown: intercomShutdown
  } = useIntercom();

  useEffect(() => {
    if (import.meta.env.PUBLIC_INTERCOM_APP_ID !== '') {
      const isAuthenticated = !!sessionUser.id;

      if (isAuthenticated && account.productPlan.id === 'trial') {
        const name =
          sessionUser.firstName && sessionUser.lastName
            ? `${sessionUser.firstName} ${sessionUser.lastName}`
            : '';
        intercomBoot();
        intercomUpdate({
          name,
          email: sessionUser.email,
          userId: sessionUser.id,
          customAttributes: { account: account.slug }
        });
        intercomShowMessages();
      } else {
        intercomShutdown();
      }
    }
  }, [account, intercomBoot, intercomUpdate, intercomShutdown, intercomShowMessages, sessionUser]);

  return children;
}

export function IntercomSetup({ children, application, sessionUser }: IntercomSetupProps) {
  const hasIntercomAppId = import.meta.env.PUBLIC_INTERCOM_APP_ID !== '';
  const isProductionWithValidAccount =
    import.meta.env.PUBLIC_IS_PRODUCTION === 'true' && !application.account.isTrial;

  // If there is a valid Intercom env key, we wrap the app in an IntercomProvider
  if (hasIntercomAppId && application.account.isTrial) {
    return (
      <IntercomProvider appId={import.meta.env.PUBLIC_INTERCOM_APP_ID}>
        {isProductionWithValidAccount ? (
          <HubspotChatSetup user={sessionUser}>
            <IntercomInit account={application.account} sessionUser={sessionUser}>
              {children}
            </IntercomInit>
          </HubspotChatSetup>
        ) : (
          <IntercomInit account={application.account} sessionUser={sessionUser}>
            {children}
          </IntercomInit>
        )}
      </IntercomProvider>
    );
  }

  // Otherwise, just check if we should render the Hubspot chat
  if (isProductionWithValidAccount) {
    return <HubspotChatSetup user={sessionUser}>{children}</HubspotChatSetup>;
  }

  // If there is no Intercom or Hubspot chat, just render the children
  return children;
}
