import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button, DropdownMenu, Label } from '@knack/asterisk-react';

import {
  type ChartType,
  type ReportView,
  type ReportViewRow
} from '@/types/schema/views/ReportView';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { EditableItemList } from '@/components/EditableItemList';
import { InlineRadioCardGroup } from '@/components/InlineRadioCardGroup';
import { ReportViewIcon } from '@/components/ReportViewIcon';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { ViewTitleDescriptionSettings } from '@/pages/pages/settings-panel/view-settings/common/general-settings/ViewTitleDescriptionSettings';
import { AddNewChartModal } from '@/pages/pages/settings-panel/view-settings/report/add-new-chart/AddNewChartModal';
import { getChartNameFromType } from '@/pages/pages/settings-panel/view-settings/report/utils';
import { useUpdateView } from '@/pages/pages/settings-panel/view-settings/useUpdateView';
import { useViewInputEditModeContext } from '@/pages/pages/settings-panel/view-settings/ViewInputEditModeContext';

const REPORT_VIEW_LAYOUT_OPTIONS: Array<{
  label: string;
  value: ReportViewRow['layout'];
}> = [
  {
    label: '1',
    value: 'one-col'
  },
  {
    label: '2',
    value: 'half'
  },
  {
    label: '3',
    value: 'thirds'
  }
];

export function ChartsSettings() {
  const [t] = useTranslation();

  const { view } = useActiveViewContext<ReportView>();
  const updateView = useUpdateView<ReportView>();
  const { setIsViewInputEditMode, setViewInputId } = useViewInputEditModeContext();

  const [newChartTypeToAdd, setNewChartTypeToAdd] = useState<ChartType>();

  const onChartDelete = (chartId: string) => {
    updateView({
      rows: [
        {
          ...view.rows[0],
          reports: view.rows[0].reports.filter((chart) => chart.id !== chartId)
        }
      ]
    });
  };

  const onChartEdit = (chartId: string) => {
    setIsViewInputEditMode(true);
    setViewInputId(chartId);
  };

  return (
    <BuilderAccordion>
      <ViewTitleDescriptionSettings isDefaultOpen={false} />

      <BuilderAccordion.Item isDefaultOpen label={t('pages.element_settings.report.heading')}>
        <div className="mb-4">
          <Label className="mb-2 block">
            {t('pages.element_settings.report.layout_columns_label')}
          </Label>
          <InlineRadioCardGroup
            itemClassName="size-10"
            options={REPORT_VIEW_LAYOUT_OPTIONS}
            value={view.rows[0].layout}
            onValueChange={(selectedChartsLayout) => {
              updateView({
                rows: [
                  {
                    ...view.rows[0],
                    layout: selectedChartsLayout as ReportViewRow['layout']
                  }
                ]
              });
            }}
          />
        </div>

        <DropdownMenu>
          <DropdownMenu.Trigger asChild>
            <Button intent="secondary" className="w-full">
              <PlusIcon size={16} className="mr-2" />
              {t('pages.element_settings.report.add_chart')}
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item onClick={() => setNewChartTypeToAdd('bar')}>
              <ReportViewIcon type="bar" className="mr-2 size-4 shrink-0" />
              {t('views.view_names.bar_chart')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => setNewChartTypeToAdd('line')}>
              <ReportViewIcon type="line" className="mr-2 size-4 shrink-0" />
              {t('views.view_names.line_chart')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => setNewChartTypeToAdd('pie')}>
              <ReportViewIcon type="pie" className="mr-2 size-4 shrink-0" />
              {t('views.view_names.pie_chart')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => setNewChartTypeToAdd('area')}>
              <ReportViewIcon type="area" className="mr-2 size-4 shrink-0" />
              {t('views.view_names.area_chart')}
            </DropdownMenu.Item>
            <DropdownMenu.Item onClick={() => setNewChartTypeToAdd('table')} disabled>
              <ReportViewIcon type="table" className="mr-2 size-4 shrink-0" />
              {t('views.view_names.pivot_table')} ({t('keywords.coming_soon')})
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>

        {newChartTypeToAdd && (
          <AddNewChartModal
            chartType={newChartTypeToAdd}
            onClose={() => setNewChartTypeToAdd(undefined)}
          />
        )}

        <EditableItemList className="mt-4">
          {view.rows[0].reports.map((chart) => (
            <EditableItemList.Item
              key={chart.id}
              isEditDisabled={chart.type === 'table'}
              itemName={chart.title || getChartNameFromType(chart.type)}
              itemIconElement={
                <ReportViewIcon className="size-4 shrink-0 text-subtle" type={chart.type} />
              }
              onDeleteClick={() => {
                onChartDelete(chart.id);
              }}
              onEditClick={() => {
                onChartEdit(chart.id);
              }}
            />
          ))}
        </EditableItemList>
      </BuilderAccordion.Item>
    </BuilderAccordion>
  );
}
