import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, Form } from '@knack/asterisk-react';
import { z } from 'zod';

import { type KnackFilter } from '@/types/schema/KnackFilter';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { CriteriaForm } from '@/components/CriteriaForm';

interface FilterFormProps {
  originObject: KnackObject;
  filter: KnackFilter;
  onCancel: () => void;
  onSave: (filter: KnackFilter) => void;
}

export function FilterForm({ originObject, filter, onCancel, onSave }: FilterFormProps) {
  const [t] = useTranslation();

  const { validateCriteriaValues } = useCriteriaHelpers();

  const defaultFiltersRulesSchema = z
    .object({
      criteria: z.custom<KnackFilter[]>()
    })
    .superRefine((data, context) => {
      const criteriaValueErrors = validateCriteriaValues(data.criteria, originObject.fields);

      if (criteriaValueErrors.length) {
        criteriaValueErrors.forEach((error) => {
          context.addIssue({
            path: error.path,
            message: t(error.message || 'errors.value_required'),
            code: 'custom'
          });
        });
      }
    });

  const form = useForm<{ criteria: KnackFilter[] }>({
    resolver: zodResolver(defaultFiltersRulesSchema),
    defaultValues: {
      criteria: [filter]
    }
  });

  const onSubmit = ({ criteria }: { criteria: KnackFilter[] }) => {
    // The CriteriaForm component handles criteria in an array, but we only need to extract the first
    onSave(criteria[0]);
  };

  return (
    <Card className="bg-muted p-2 shadow-none sm:p-2">
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CriteriaForm
            className="p-0"
            criteriaType="filter"
            sourceObject={originObject}
            isSingleCriteriaForm
          />

          <Form.Section className="mt-2 flex justify-end gap-2">
            <Button intent="secondary" onClick={onCancel}>
              {t('actions.cancel')}
            </Button>
            <Button type="submit">{t('actions.save')}</Button>
          </Form.Section>
        </form>
      </FormProvider>
    </Card>
  );
}
