import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Banner,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Input,
  Label,
  RichTextEditor,
  Select
} from '@knack/asterisk-react';
import { z } from 'zod';

import {
  FORM_VIEW_SUBMIT_RULE_ACTION_TYPES,
  type FormView,
  type FormViewSubmitRule
} from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { usePageHelpers } from '@/hooks/helpers/usePageHelpers';
import { isDateTimeRangeOperator, shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { CriteriaForm } from '@/components/CriteriaForm';
import { usePageEditorContext } from '@/pages/pages/page-editor/PageEditorContext';
import { useActiveViewContext } from '@/pages/pages/settings-panel/view-settings/ActiveViewContextProvider';
import { useFormSubmitRulesHelpers } from '@/pages/pages/settings-panel/view-settings/form/submit-rules/useFormSubmitRulesHelpers';

interface FormSubmitRuleFormDialogProps {
  submitRule: FormViewSubmitRule;
  formIntent?: 'edit' | 'add';
  onOpenChange: (isOpen: boolean) => void;
  onRuleSave: (updatedSubmitRule: FormViewSubmitRule) => void;
}

export function FormSubmitRuleFormDialog({
  submitRule,
  formIntent = 'edit',
  onOpenChange,
  onRuleSave
}: FormSubmitRuleFormDialogProps) {
  const [t] = useTranslation();

  const { view, sourceObject } = useActiveViewContext<FormView>();
  const { page: activePage } = usePageEditorContext();
  const { getElegibleLinkablePagesFromObjectKey } = usePageHelpers();
  const { isCriteriaValueEmpty } = useCriteriaHelpers();
  const { getDefaultSubmitRuleCriteria } = useFormSubmitRulesHelpers(sourceObject);

  const elegibleLinkablePagesFromObjectKey = getElegibleLinkablePagesFromObjectKey(
    sourceObject.key
  );

  const submitRuleFormSchema = z.custom<FormViewSubmitRule>().superRefine((data, ctx) => {
    if (data.action === 'message' && !data.message) {
      ctx.addIssue({
        path: ['message'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    if (data.action === 'url' && !data.url) {
      ctx.addIssue({
        path: ['url'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    // If the action is 'child_page' and there isn't a child page already associated with the rule, we need to validate the name of the new child page.
    // If `scene` exists but it's a string, it means it is the slug of an existing child page. If it's an object, it means it's the data for a new child page to be created.
    if (
      data.action === 'child_page' &&
      data.scene &&
      typeof data.scene !== 'string' &&
      !data.scene.name.trim()
    ) {
      ctx.addIssue({
        path: ['scene'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    if (data.action === 'existing_page' && !data.existing_page) {
      ctx.addIssue({
        path: ['existing_page'],
        message: t('errors.value_required'),
        code: 'custom'
      });
    }

    // Validate criteria values
    data.criteria?.forEach((criteria, criteriaIndex) => {
      // Validate criteria value if the value type is not a field and the operator requires a value
      const isValueRequired =
        criteria.value_type !== 'field' &&
        !shouldHideValueBasedOnOperator(criteria.operator) &&
        !isDateTimeRangeOperator(criteria.operator);

      const selectedCriteriaField = sourceObject.fields.find(
        (field) => field.key === criteria.field
      );
      const isValueEmpty = selectedCriteriaField
        ? isCriteriaValueEmpty(criteria.value, selectedCriteriaField)
        : true;

      if (isValueRequired && isValueEmpty) {
        ctx.addIssue({
          path: [`criteria.${criteriaIndex}.value`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }

      // Validate criteria value field if the value type is a field and the operator requires a value
      const isFieldRequired =
        criteria.value_type === 'field' && !shouldHideValueBasedOnOperator(criteria.operator);
      const isFieldMissing = !criteria.value_field;

      if (isFieldRequired && isFieldMissing) {
        ctx.addIssue({
          path: [`criteria.${criteriaIndex}.value_field`],
          message: t('errors.value_required'),
          code: 'custom'
        });
      }
    });
  });

  const initialSubmitRuleValues = {
    ...submitRule
  };

  // Sanitize the scene value if the action is 'child_page' and there is no child page with that page slug
  if (
    submitRule.scene &&
    typeof submitRule.scene === 'string' &&
    !elegibleLinkablePagesFromObjectKey.some((page) => page.slug === submitRule.scene)
  ) {
    initialSubmitRuleValues.scene = null;
  }

  const form = useForm<FormViewSubmitRule>({
    resolver: zodResolver(submitRuleFormSchema),
    defaultValues: initialSubmitRuleValues
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors }
  } = form;

  const {
    action: currentSelectedActionType,
    reload_auto: currentSelectedReloadAuto,
    reload_show: currentSelectedReloadShow
  } = watch();

  const onSubmit = (updatedSubmitRule: FormViewSubmitRule) => {
    const formattedSubmitRule = {
      ...updatedSubmitRule
    };

    // If the action is 'child_page' and the child page doesn't already exist, we need to set the `scene` to be an object with specific properties so the API can create the page.
    // Once the page is created, the rule's `scene` value will be a string containing the new page's slug.
    if (
      currentSelectedActionType === 'child_page' &&
      !initialSubmitRuleValues.scene &&
      updatedSubmitRule.scene &&
      typeof updatedSubmitRule.scene !== 'string'
    ) {
      formattedSubmitRule.scene = {
        name: updatedSubmitRule.scene.name,
        object: sourceObject.key,
        parent: activePage.slug,
        allowed_profiles: activePage.allowedProfileKeys || [],
        views: []
      };
    }

    onRuleSave(formattedSubmitRule);
  };

  return (
    <Dialog open onOpenChange={onOpenChange}>
      <Dialog.Content>
        <FormProvider {...form}>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <Dialog.MainContent>
              <Dialog.Header>
                <Dialog.Title>
                  {formIntent === 'edit'
                    ? t('components.submit_rule_card.edit_modal.title')
                    : t('components.submit_rule_card.add_modal.title')}
                </Dialog.Title>
                <Dialog.Description className="sr-only">
                  {formIntent === 'edit'
                    ? t('components.submit_rule_card.edit_modal.description')
                    : t('components.submit_rule_card.add_modal.description')}
                </Dialog.Description>
              </Dialog.Header>

              <div className="mt-6">
                <div className="mb-4">
                  <p className="mb-2 font-medium">
                    {t('components.submit_rule_card.edit_modal.action_type')}
                  </p>
                  <Controller
                    control={control}
                    name="action"
                    render={({ field: { value: selectedActionType, onChange } }) => (
                      <Select value={selectedActionType} onValueChange={onChange}>
                        <Select.Trigger
                          placeholder={t('actions.select')}
                          className={cn('w-full', {
                            'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                              errors?.action
                          })}
                        />
                        <Select.Content>
                          {FORM_VIEW_SUBMIT_RULE_ACTION_TYPES.map((actionType) => (
                            <Select.Item key={actionType} value={actionType}>
                              {t(`components.submit_rule_card.actions.${actionType}`)}
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select>
                    )}
                  />
                </div>

                {currentSelectedActionType === 'message' && (
                  <div>
                    <p className="mb-2 font-medium">
                      {t('components.submit_rule_card.edit_modal.confirmation_message')}
                    </p>
                    <Controller
                      control={control}
                      name="message"
                      render={({ field: { value: message, onChange } }) => (
                        <RichTextEditor
                          onUpdate={({ editor }) => {
                            onChange(editor.getText() ? editor.getHTML() : '');
                          }}
                          content={message || ''}
                        />
                      )}
                    />
                    {errors.message && (
                      <p className="mt-1 text-destructive">{errors.message.message}</p>
                    )}
                    <p className="mt-4 font-medium">
                      {t('components.submit_rule_card.edit_modal.and')}
                    </p>
                    <div className="mt-2">
                      <div className="flex items-center">
                        <Controller
                          control={control}
                          name="reload_show"
                          render={({ field: { value, onChange } }) => (
                            <Checkbox
                              id={`${submitRule.key}-show-reload`}
                              checked={value}
                              onCheckedChange={(newValue) => {
                                // Toggle the other option off if the current one is being toggled on
                                if (currentSelectedReloadAuto) {
                                  setValue('reload_auto', false);
                                }
                                onChange(newValue);
                              }}
                            />
                          )}
                        />
                        <Label htmlFor={`${submitRule.key}-show-reload`} className="ml-2">
                          {t('components.submit_rule_card.additional_actions.reload_show')}
                        </Label>
                      </div>
                      <div className="mt-1 flex items-center">
                        <Controller
                          control={control}
                          name="reload_auto"
                          render={({ field: { value, onChange } }) => (
                            <Checkbox
                              id={`${submitRule.key}-auto-reload`}
                              checked={value}
                              onCheckedChange={(newValue) => {
                                // Toggle the other option off if the current one is being toggled on
                                if (currentSelectedReloadShow) {
                                  setValue('reload_show', false);
                                }
                                onChange(newValue);
                              }}
                            />
                          )}
                        />
                        <Label htmlFor={`${submitRule.key}-auto-reload`} className="ml-2">
                          {t('components.submit_rule_card.additional_actions.reload_auto')}
                        </Label>
                      </div>
                    </div>
                  </div>
                )}

                {currentSelectedActionType === 'existing_page' && (
                  <div>
                    <p className="mb-2 font-medium">
                      {t('components.submit_rule_card.edit_modal.existing_page')}
                    </p>
                    <Controller
                      control={control}
                      name="existing_page"
                      render={({ field: { value: existingPage, onChange } }) => (
                        <Select value={existingPage} onValueChange={onChange}>
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.existing_page
                            })}
                          />
                          <Select.Content>
                            {elegibleLinkablePagesFromObjectKey.map((page) => (
                              <Select.Item key={page.slug} value={page.slug}>
                                {page.label}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                    {errors.existing_page && (
                      <p className="mt-1 text-destructive">{errors.existing_page.message}</p>
                    )}
                  </div>
                )}

                {currentSelectedActionType === 'child_page' &&
                  typeof initialSubmitRuleValues.scene === 'string' && (
                    <p className="mb-2 text-xs text-subtle">
                      <Trans
                        i18nKey="components.submit_rule_card.edit_modal.the_user_will_be_redirected_to"
                        components={[<span key="0" className="underline" />]}
                        values={{
                          url: elegibleLinkablePagesFromObjectKey.find(
                            (page) => page.slug === submitRule.scene
                          )?.label
                        }}
                      />
                    </p>
                  )}

                {currentSelectedActionType === 'child_page' &&
                  ((initialSubmitRuleValues.scene &&
                    typeof initialSubmitRuleValues.scene !== 'string') ||
                    !initialSubmitRuleValues.scene) && (
                    <div>
                      <p className="mb-2 font-medium">
                        {t('components.submit_rule_card.edit_modal.new_child_page_name')}
                      </p>
                      <Input
                        intent={errors.scene?.root ? 'destructive' : undefined}
                        placeholder={t('actions.enter_value')}
                        {...register('scene.name')}
                      />
                      {errors.scene?.root && (
                        <p className="mt-1 text-destructive">{errors.scene.root.message}</p>
                      )}
                    </div>
                  )}

                {currentSelectedActionType === 'url' && (
                  <div>
                    <p className="mb-2 font-medium">
                      {t('components.submit_rule_card.edit_modal.redirect_url')}
                    </p>
                    <Input
                      intent={errors.url ? 'destructive' : undefined}
                      placeholder={t('actions.enter_value')}
                      {...register('url')}
                    />
                    {errors.url && <p className="mt-1 text-destructive">{errors.url.message}</p>}
                  </div>
                )}

                <Divider className="my-6" />

                {submitRule.is_default ? (
                  <Banner className="bg-subtle text-default">
                    {t('components.submit_rule_card.edit_modal.default_action_description')}
                  </Banner>
                ) : (
                  <>
                    <h3 className="mb-1 font-medium">{t('components.rules.conditions')}</h3>
                    <p className="mb-4 text-xs text-subtle">
                      {t('components.rules.apply_action_when_conditions_are_met')}
                    </p>

                    <CriteriaForm
                      className="rounded-lg bg-subtle"
                      sourceObject={sourceObject}
                      criteriaType="submit-rule"
                      defaultCriteria={getDefaultSubmitRuleCriteria(view.action)}
                      shouldHaveContent
                    />
                  </>
                )}
              </div>
            </Dialog.MainContent>
            <Dialog.Footer>
              <Dialog.Close asChild>
                <Button intent="minimal">{t('actions.cancel')}</Button>
              </Dialog.Close>
              <Button type="submit">
                {formIntent === 'edit'
                  ? t('components.submit_rule_card.edit_modal.submit_button')
                  : t('components.submit_rule_card.add_modal.submit_button')}
              </Button>
            </Dialog.Footer>
          </form>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
}
