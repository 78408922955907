import { type DateTimeRangeType } from '@/types/schema/fields';
import { type KnackFieldKey } from '@/types/schema/KnackField';
import { type KnackOperator } from '@/types/schema/KnackOperator';

export const KNACK_CRITERIA_VALUE_TYPES = ['custom', 'field'] as const;
export type KnackCriteriaValueType = (typeof KNACK_CRITERIA_VALUE_TYPES)[number];

export type KnackCriteria = {
  field: KnackFieldKey;
  operator: KnackOperator;
  value:
    | string

    // Only for boolean fields
    | boolean

    // If the value is an array of strings, it means the field is a connection. The array will contain the id of the record (e.g. ["66b3d6f8f160620293d83294"])
    | string[]

    // Value for date/time fields
    | KnackCriteriaDateTimeValue;

  // These properties are specific to date/time fields
  range?: string | number;
  type?: DateTimeRangeType;
};

export type KnackCriteriaWithValueType = KnackCriteria & {
  value_type?: KnackCriteriaValueType;
  value_field?: KnackFieldKey;
};

export interface KnackCriteriaDateTimeValue {
  date?: string;
  all_day?: boolean;
  hours?: number | string;
  minutes?: number | string;
  time?: string;
  am_pm?: 'AM' | 'PM' | '';
}
