import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Input, Label, Select, Tooltip, useToast } from '@knack/asterisk-react';
import { isAxiosError } from 'axios';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import pick from 'lodash/pick';
import { z, type ZodSchema } from 'zod';

import {
  KNACK_FIELD_TYPES,
  type KnackField,
  type KnackFieldKey,
  type KnackFieldType
} from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCreateFieldMutation } from '@/hooks/api/mutations/useCreateFieldMutation';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { FIELD_NAME_MAX_LENGTH } from '@/utils/constants';
import { generateUniqueStringInList, isStringUniqueInList } from '@/utils/uniqueStrings';
import {
  AddressFormSettings,
  addressSchema
} from '@/components/field-settings/address/AddressFormSettings';
import {
  AggregateFormSettings,
  aggregateSchema,
  countSchema
} from '@/components/field-settings/aggregate/AggregateFormSettings';
import {
  BooleanFormSettings,
  booleanSchema
} from '@/components/field-settings/boolean/BooleanFormSettings';
import {
  ConcatenationFormSettings,
  concatenationSchema
} from '@/components/field-settings/concatenation/ConcatenationFormSettings';
import {
  ConnectionFormSettings,
  connectionSchema
} from '@/components/field-settings/connection/ConnectionFormSettings';
import {
  CurrencyFormSettings,
  currencySchema
} from '@/components/field-settings/currency/CurrencyFormSettings';
import {
  DateTimeFormSettings,
  dateTimeSchema
} from '@/components/field-settings/date-time/DateTimeFormSettings';
import { DefaultValue } from '@/components/field-settings/DefaultValue';
import { Description } from '@/components/field-settings/Description';
import {
  DurationFormSettings,
  durationSchema
} from '@/components/field-settings/duration/DurationFormSettings';
import {
  EmailFormSettings,
  emailSchema
} from '@/components/field-settings/email/EmailFormSettings';
import {
  EquationFormSettings,
  equationSchema
} from '@/components/field-settings/equation/EquationFormSettings';
import { FileFormSettings, fileSchema } from '@/components/field-settings/file/FileFormSettings';
import {
  ImageFormSettings,
  imageSchema
} from '@/components/field-settings/image/ImageFormSettings';
import { LinkFormSettings, linkSchema } from '@/components/field-settings/link/LinkFormSettings';
import {
  MultipleChoiceFormSettings,
  multipleChoiceSchema
} from '@/components/field-settings/multiple-choice/MultipleChoiceFormSettings';
import { NameFormSettings, nameSchema } from '@/components/field-settings/name/NameFormSettings';
import {
  NumberFormSettings,
  numberSchema
} from '@/components/field-settings/number/NumberFormSettings';
import {
  PhoneFormSettings,
  phoneSchema
} from '@/components/field-settings/phone/PhoneFormSettings';
import {
  RatingFormSettings,
  ratingSchema
} from '@/components/field-settings/rating/RatingFormSettings';
import { RequiredSwitch } from '@/components/field-settings/RequiredSwitch';
import { UniqueSwitch } from '@/components/field-settings/UniqueSwitch';
import { areAggregateFieldsEnabledForObjectKey } from '@/components/field-settings/utils/areAggregateFieldsEnabledForObjectKey';
import { FieldIcon } from '@/components/FieldIcon';

export type NewFieldState = {
  isBeingCreated: boolean;
  fromFieldId?: KnackFieldKey;
  newIndex?: number;
  shouldNotAllowTypeChange?: boolean;
};

type FieldSettingsProps = {
  objectKey: string;
  fieldId?: string;
  initialType?: KnackField['type'];
  onCancel: () => void;
  onSave: () => void;
  newFieldState: NewFieldState;
};

type FieldSettingsMap = {
  [key in KnackField['type']]: {
    schema: ZodSchema | null;
    omit?: Record<string, boolean>;
    component:
      | ((props: { field: any; isNewField: boolean; objectKey: KnackObject['key'] }) => JSX.Element)
      | null;
  };
};

const isAggregateField = (type: KnackFieldType) => ['sum', 'min', 'max', 'average'].includes(type);

export function FieldSettings({
  objectKey,
  fieldId,
  initialType,
  onCancel,
  onSave,
  newFieldState
}: FieldSettingsProps) {
  const { data: app } = useApplicationQuery();
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const { mutate: updateField, isPending: isUpdateFieldLoading } = useFieldMutation();
  const { mutate: createField, isPending: isCreateFieldLoading } = useCreateFieldMutation();

  const table = app?.objects?.find((obj) => obj?.key === objectKey);
  const fieldKey = newFieldState.fromFieldId ?? fieldId;
  const field = table?.fields?.find((obj) => obj?.key === fieldKey);
  const existingFieldNames = table?.fields?.map((f) => f.name) || [];

  const [fieldType, setFieldType] = useState(field?.type || initialType || '');

  const fieldSettingsMap: FieldSettingsMap = {
    short_text: {
      schema: null,
      component: null
    },
    number: {
      schema: numberSchema,
      component: NumberFormSettings
    },
    email: {
      schema: emailSchema,
      omit: { default: true },
      component: EmailFormSettings
    },
    rating: {
      schema: ratingSchema,
      omit: { default: true, required: true, unique: true },
      component: RatingFormSettings
    },
    name: {
      schema: nameSchema,
      omit: { default: true, unique: true },
      component: NameFormSettings
    },
    link: {
      schema: linkSchema,
      component: LinkFormSettings
    },
    address: {
      schema: addressSchema,
      omit: { unique: true, default: true },
      component: AddressFormSettings
    },
    rich_text: {
      schema: null,
      component: null
    },
    paragraph_text: {
      schema: null,
      component: null
    },
    auto_increment: {
      schema: null,
      omit: { required: true, unique: true, default: true },
      component: null
    },
    signature: {
      schema: null,
      omit: { unique: true, default: true },
      component: null
    },
    currency: {
      schema: currencySchema,
      component: CurrencyFormSettings
    },
    sum: {
      schema: aggregateSchema,
      omit: { required: true, unique: true, default: true },
      component: AggregateFormSettings
    },
    min: {
      schema: aggregateSchema,
      omit: { required: true, unique: true, default: true },
      component: AggregateFormSettings
    },
    max: {
      schema: aggregateSchema,
      omit: { required: true, unique: true, default: true },
      component: AggregateFormSettings
    },
    average: {
      schema: aggregateSchema,
      omit: { required: true, unique: true, default: true },
      component: AggregateFormSettings
    },
    count: {
      schema: countSchema,
      omit: { required: true, unique: true, default: true },
      component: AggregateFormSettings
    },
    date_time: {
      schema: dateTimeSchema,
      omit: { unique: true, default: true },
      component: DateTimeFormSettings
    },
    timer: {
      schema: durationSchema,
      omit: { unique: true, default: true },
      component: DurationFormSettings
    },
    boolean: {
      schema: booleanSchema,
      omit: { unique: true },
      component: BooleanFormSettings
    },
    multiple_choice: {
      schema: multipleChoiceSchema,
      omit: { unique: true },
      component: MultipleChoiceFormSettings
    },
    file: {
      schema: fileSchema,
      omit: { unique: true, default: true },
      component: FileFormSettings
    },
    image: {
      schema: imageSchema,
      omit: { unique: true, default: true },
      component: ImageFormSettings
    },
    phone: {
      schema: phoneSchema,
      component: PhoneFormSettings
    },
    connection: {
      schema: connectionSchema,
      omit: { unique: true },
      component: ConnectionFormSettings
    },
    concatenation: {
      schema: concatenationSchema,
      omit: { required: true, unique: true, default: true },
      component: ConcatenationFormSettings
    },
    equation: {
      schema: equationSchema,
      omit: { required: true, unique: true, default: true },
      component: EquationFormSettings
    },
    password: {
      schema: null,
      component: null
    },
    user_roles: {
      schema: null,
      component: null
    }
  };

  const formSettingsSharedSchema = z.object({
    name: z
      .string()
      .min(1, {
        message: t('components.data_table.attributes.field_settings.errors.required_prop', {
          prop: t('components.data_table.attributes.field_settings.common.name')
        })
      })
      .max(
        FIELD_NAME_MAX_LENGTH,
        t('components.data_table.errors.field_max_length', {
          field: 'name',
          max: FIELD_NAME_MAX_LENGTH
        })
      )
      .refine(
        (val) =>
          isStringUniqueInList(
            val,
            existingFieldNames.filter((name) => name !== field?.name)
          ),
        (val) => ({
          message: t('components.data_table.attributes.field_settings.errors.name_being_used', {
            fieldName: val
          })
        })
      ),
    required: z.boolean(),
    unique: z.boolean(),
    default: z.union([z.string(), z.number(), z.boolean()]).optional(),
    meta: z.object({
      description: z.string().optional()
    }),
    type: z.enum(KNACK_FIELD_TYPES)
  });

  const currentFieldSettings = fieldSettingsMap[fieldType];

  const formSettingsSchema =
    fieldType !== ''
      ? formSettingsSharedSchema
          .omit(currentFieldSettings?.omit || {})
          .merge(currentFieldSettings?.schema ? currentFieldSettings.schema : z.object({}))
      : formSettingsSharedSchema;

  type FieldSettingsFormSchema = z.infer<typeof formSettingsSchema>;

  const getFieldName = () => {
    if (newFieldState.fromFieldId) {
      // If it's a new field being created from an existing field, use the existing field name as a base
      const fieldNameParts = field?.name.split(' ') || [];
      const isNumeric = (str) => /^\d+$/.test(str);
      if (isNumeric(fieldNameParts.at(-1))) {
        fieldNameParts.pop();
      }
      const baseFieldName = fieldNameParts.join(' ') || '';
      return generateUniqueStringInList(existingFieldNames, baseFieldName);
    }

    return (
      field?.name ||
      generateUniqueStringInList(
        existingFieldNames,
        t('components.data_table.attributes.field_settings.common.default_field_name')
      ) ||
      ''
    );
  };

  const methods = useForm<FieldSettingsFormSchema>({
    resolver: zodResolver(formSettingsSchema),
    defaultValues: {
      name: getFieldName(),
      required: field?.required || false,
      unique: field?.unique || false,
      default: field?.default || '',
      meta: {
        description: field?.meta?.description || ''
      },
      format: field?.format,
      type: field?.type || fieldType,
      relationship: field?.relationship
    },
    mode: 'onChange'
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    getValues,
    reset,
    setFocus
  } = methods;

  const onSubmit = (data: FieldSettingsFormSchema) => {
    const newFieldData = {
      ...data,
      type: fieldType
    };

    if (newFieldState.isBeingCreated && newFieldState?.newIndex !== undefined) {
      return createField(
        {
          tableKey: objectKey,
          fieldData: newFieldData as KnackField,
          fieldIndex: newFieldState.newIndex
        },
        {
          onSuccess: () => {
            presentToast({
              title: t('components.data_table.attributes.field_settings.common.create_success')
            });
            onSave();
          },
          onError: () => {
            presentToast({
              title: t('components.data_table.attributes.field_settings.common.create_error')
            });
          }
        }
      );
    }

    return updateField(
      {
        tableKey: objectKey,
        updatedField: {
          ...field,
          ...newFieldData
        } as KnackField
      },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.data_table.attributes.field_settings.common.save_success')
          });
          onSave();
        },
        onError: (error) => {
          if (isAxiosError(error) && error.response) {
            // Server errors will be considered root errors and we will set the focus to the name field in order to scroll up to the error
            setError('root', { message: error.response.data.error });
            setFocus('name');
          }
          presentToast({
            title: t('components.data_table.attributes.field_settings.common.save_error')
          });
        }
      }
    );
  };
  const isImmutable = field?.immutable;

  const shouldShowRequired = !!formSettingsSchema.shape.required && !isImmutable;
  const shouldShowUnique = !!formSettingsSchema.shape.unique && !isImmutable;
  const shouldShowDefaultValue = !!formSettingsSchema.shape.default;
  const shouldShowDescription = !!formSettingsSchema.shape.meta.shape.description && !isImmutable;

  const allCurrentFormValues = watch();
  const previousFormValues = pick(
    {
      ...field,
      default: field?.default || '',
      format: field?.format,
      relationship: field?.relationship,
      meta: field?.meta || { description: '' }
    },
    Object.keys(allCurrentFormValues)
  );
  const shouldDisableSaveButton =
    isEqual(previousFormValues, allCurrentFormValues) ||
    isUpdateFieldLoading ||
    isCreateFieldLoading;

  const CurrentFieldComponent = currentFieldSettings.component;

  const errorMessages = Object.keys(errors)
    .map((key) => {
      const error = errors[key];
      if (error.message) {
        if (key === 'root') {
          return `${error.message}`;
        }

        return `${key}: ${error.message}`;
      }

      return Object.keys(error).map((key2) => {
        if (Array.isArray(error[key2])) {
          return error[key2].map((errorItem) => `${key2}: ${errorItem.message}\n`);
        }

        return `${key2}: ${error[key2].message}`;
      });
    })
    .flat();

  const areAggregateFieldsEnabled = useMemo(
    () => app && areAggregateFieldsEnabledForObjectKey(objectKey, app),
    [app, objectKey]
  );

  const hasManyConnections = useMemo(() => {
    const hasInboundConnectionWithNumberField = table?.connections?.inbound?.some(
      (connection) => connection.belongs_to === 'many'
    );

    const hasOutboundConnectionWithNumberField = table?.connections?.outbound?.some(
      (connection) => connection.has === 'many'
    );
    return hasInboundConnectionWithNumberField || hasOutboundConnectionWithNumberField;
  }, [table?.connections?.inbound, table?.connections?.outbound]);

  const shouldDisableAggregateFields = (type: KnackFieldType) =>
    (isAggregateField(type) && !areAggregateFieldsEnabled) ||
    (type === 'count' && !hasManyConnections);

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit(onSubmit)}
        data-testid={`edit-field-settings-form-${fieldId || newFieldState.newIndex}`}
      >
        {!isEmpty(errorMessages) && (
          <div className="w-20 min-w-full rounded-lg bg-destructive p-2 text-destructive">
            {t('components.data_table.attributes.field_settings.errors.correct_errors_title')}
            <ul className="ml-4 mt-1 list-disc">
              {errorMessages.map((message) => !!message && <li key={message}>{message}</li>)}
            </ul>
          </div>
        )}
        <div className="flex flex-col gap-2">
          <Label htmlFor="field-settings-name-input" className="font-medium">
            {t('components.data_table.attributes.field_settings.common.name')}
          </Label>
          <Input
            id="field-settings-name-input"
            data-testid={`field-settings-name-input-${fieldId || newFieldState.newIndex}`}
            className="w-full"
            {...register('name', {
              onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.trim();
              }
            })}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor="field-settings-data-type-select" className="font-medium">
            {t('components.data_table.attributes.field_settings.common.data_type')}
          </Label>
          <Select
            onValueChange={(value) => {
              setFieldType(value as KnackFieldType);
              // Reset all the values in the schema except from meta (description) and name
              reset(
                {
                  type: value,
                  format: undefined,
                  meta: getValues('meta'),
                  name: getValues('name'),
                  required: false,
                  unique: false
                },
                { keepDefaultValues: true }
              );
            }}
            value={getValues('type') || fieldType}
            disabled={
              !newFieldState.isBeingCreated ||
              (newFieldState.isBeingCreated && newFieldState.shouldNotAllowTypeChange)
            }
          >
            <Select.Trigger
              id="field-settings-data-type-select"
              placeholder="Type"
              className="w-full"
              data-testid={`field-type-select-${fieldId || newFieldState.newIndex}`}
            />
            <Select.Content>
              <div className="max-h-72 overflow-y-auto">
                {Object.keys(fieldSettingsMap)
                  .filter((fieldTypeOption) =>
                    newFieldState.isBeingCreated
                      ? fieldTypeOption !== 'password' && fieldTypeOption !== 'user_roles'
                      : true
                  )
                  .map((fieldTypeOption) => (
                    <Tooltip key={fieldTypeOption}>
                      <Tooltip.Trigger asChild>
                        <Select.Item
                          key={fieldTypeOption}
                          value={fieldTypeOption}
                          disabled={shouldDisableAggregateFields(fieldTypeOption as KnackFieldType)}
                        >
                          <div
                            className="flex items-center text-base"
                            data-testid={`field-data-type-item-${fieldTypeOption}`}
                          >
                            <FieldIcon
                              name={fieldTypeOption as KnackFieldType}
                              size={14}
                              className="mr-1"
                            />
                            {t(`attributes.field_types.${fieldTypeOption}`)}
                          </div>
                        </Select.Item>
                      </Tooltip.Trigger>
                      {shouldDisableAggregateFields(fieldTypeOption as KnackFieldType) && (
                        <Tooltip.Content>
                          {t(
                            'components.data_table.attributes.field_settings.common.aggregate_field_disabled_tooltip'
                          )}
                        </Tooltip.Content>
                      )}
                    </Tooltip>
                  ))}
              </div>
            </Select.Content>
          </Select>
        </div>
        {fieldType !== '' && currentFieldSettings?.component && (
          <CurrentFieldComponent
            field={field || { type: fieldType }}
            isNewField={newFieldState.isBeingCreated}
            objectKey={objectKey}
          />
        )}
        {!isImmutable && <Divider className="my-2" />}
        {shouldShowRequired && <RequiredSwitch fieldType={fieldType as KnackFieldType} />}
        {shouldShowUnique && <UniqueSwitch />}
        {shouldShowDefaultValue && (
          <DefaultValue
            defaultValue={fieldType === 'boolean' ? field?.format : field?.default}
            fieldType={fieldType as KnackFieldType}
          />
        )}
        {shouldShowDescription && <Description description={field?.meta?.description} />}
        <div className="mt-4 flex justify-end gap-2">
          <Button
            intent="secondary"
            onClick={onCancel}
            data-testid={`field-settings-cancel-button-${fieldId || newFieldState.newIndex}`}
          >
            {t('actions.cancel')}
          </Button>

          <Button
            type="submit"
            data-testid={`submit-field-settings-button-${fieldId || newFieldState.newIndex}`}
            disabled={shouldDisableSaveButton}
          >
            {t('actions.save')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
}
