import { useState } from 'react';
import { FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Divider, Form, Select, useToast } from '@knack/asterisk-react';
import { z } from 'zod';

import { type ConditionalRule, type KnackField } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useFieldMutation } from '@/hooks/api/mutations/useFieldMutation';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useGlobalState } from '@/hooks/useGlobalStore';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { TextTooltip } from '@/components/TextTooltip';
import { CriteriaForm } from '@/pages/tables/toolkit-sidebar/rules/CriteriaForm';
import { RuleFormHeader } from '@/pages/tables/toolkit-sidebar/rules/RuleFormHeader';
import { getCompatibleFields } from '@/pages/tables/toolkit-sidebar/rules/utils';
import { ConditionalRuleFormActionValue } from './form-sections/ConditionalRuleFormActionValue';
import { ConditionalRuleFormTargetFieldKey } from './form-sections/ConditionalRuleFormTargetFieldKey';

type RunConditionType = 'custom_criteria' | 'run_with_every_record';

type ActionValue = {
  field: string;
  type: 'value';
  value: string | boolean | null;
  input: string;
};

type ActionRecord = {
  field: string;
  type: 'record';
  value?: string | undefined;
  input: string;
};

const actionValue = z.object({
  field: z.string(),
  type: z.literal('value'),
  value: z.union([
    z.string(),
    z.boolean(),
    z.number(),
    z.null(),
    z.record(z.any()),
    z.array(z.union([z.string(), z.record(z.any())]))
  ]),
  input: z.string()
});

const actionRecord = z.object({
  field: z.string(),
  type: z.literal('record'),
  value: z.string().optional(),
  input: z.string()
});

const actionSchema = z.union([actionValue, actionRecord]);

export type ConditionalRuleFormSchema = z.infer<typeof conditionalRuleFormSchema>;

const conditionalRuleFormSchema = z
  .object({
    targetFieldKey: z.string().min(1),
    criteria: z.array(
      z.object({
        field: z.string().min(1),
        operator: z.string().min(1),
        value: z.union([z.string(), z.boolean(), z.array(z.string()), z.any()]),
        value_field: z.string().min(1),
        value_type: z.string().min(1),
        type: z.string().optional(),
        range: z.string().optional()
      })
    ),
    action: actionSchema
  })
  .superRefine((data, context) => {
    if (data.action.type === 'record' && !data.action.input) {
      context.addIssue({
        path: ['action', 'input'],
        code: 'custom'
      });
    }

    if (
      data.action.value &&
      typeof data.action.value === 'object' &&
      'password' in data.action.value &&
      'password_confirmation' in data.action.value
    ) {
      const { password, password_confirmation: passwordConfirmation } = data.action.value;
      if (!password || password.trim() === '') {
        context.addIssue({
          path: ['action', 'value', 'password'],
          code: 'custom'
        });
      }

      if (password !== passwordConfirmation) {
        context.addIssue({
          path: ['action', 'value', 'password'],
          code: 'custom'
        });
      }
    }

    data.criteria.forEach((criterion, index) => {
      const { operator, value, value_type: valueType, type } = criterion;

      if (
        valueType !== 'field' &&
        !shouldHideValueBasedOnOperator(operator) &&
        (value === '' ||
          value === null ||
          value === undefined ||
          (typeof value === 'string' && value.trim() === '')) &&
        !type
      ) {
        context.addIssue({
          path: ['criteria', index, 'value'],
          code: 'custom'
        });
      }
    });
  });

export function ConditionalRulesForm({
  table,
  setShouldRenderForm,
  fieldKey,
  ruleToEdit,
  ruleToDuplicate,
  onFieldUpdate,
  setRuleToDuplicate
}: {
  table: KnackObject;
  setShouldRenderForm: React.Dispatch<React.SetStateAction<boolean>>;
  fieldKey?: string;
  ruleToEdit?: ConditionalRule;
  ruleToDuplicate?: ConditionalRule;
  onFieldUpdate?: () => void;
  setRuleToDuplicate?: React.Dispatch<
    React.SetStateAction<{ rule: ConditionalRule; fieldKey: string } | null>
  >;
}) {
  const [t] = useTranslation();
  const { presentToast } = useToast();

  const { mutate: updateField } = useFieldMutation();
  const { getDefaultCriteriaOperator } = useCriteriaHelpers();
  const isDraftModeEnabled = useGlobalState((state) => state.isDraftModeEnabled);

  const rule = ruleToEdit ?? ruleToDuplicate;

  const CONDITIONAL_CRITERIA_VALUE_TYPES = [
    {
      key: 'custom',
      name: t('components.data_table.right_sidebar.conditional_rules.custom_value')
    },
    {
      key: 'field',
      name: t('components.data_table.right_sidebar.conditional_rules.field_value')
    }
  ];

  const RUN_CONDITIONS = [
    {
      key: 'run_with_every_record',
      name: t('components.data_table.right_sidebar.conditional_rules.run_with_every_record')
    },
    {
      key: 'custom_criteria',
      name: t('components.data_table.right_sidebar.conditional_rules.custom_criteria')
    }
  ];

  const compatibleFields = getCompatibleFields(table.fields);

  const defaultCriteria = {
    field: compatibleFields[0].key as string,
    operator: getDefaultCriteriaOperator(compatibleFields[0], 'conditional-rule'),
    value: '',
    value_type: CONDITIONAL_CRITERIA_VALUE_TYPES[0].key,
    value_field: compatibleFields[0].key as string,
    type: ''
  };

  const defaultAction: ActionValue | ActionRecord = {
    field: '',
    type: 'value',
    value: '',
    input: compatibleFields[0].key
  };

  let defaultValues: ConditionalRuleFormSchema = {
    targetFieldKey: '',
    criteria: [defaultCriteria],
    action: defaultAction
  };

  if (rule && fieldKey) {
    const criteria = rule.criteria.map((c) => ({
      field: c.field,
      operator: c.operator,
      value: c.value,
      value_field: c.value_field as string,
      value_type: c.value_type
    }));

    defaultValues = {
      targetFieldKey: fieldKey,
      criteria,
      action: rule.values[0] as ActionValue | ActionRecord
    };
  }

  const defaultRunCondition: RunConditionType =
    defaultValues.criteria.length > 0 ? 'custom_criteria' : 'run_with_every_record';
  const [runCondition, setRunCondition] = useState<RunConditionType>(defaultRunCondition);

  const formMethods = useForm<ConditionalRuleFormSchema>({
    resolver: zodResolver(conditionalRuleFormSchema),
    defaultValues
  });

  const { watch, handleSubmit, setValue, trigger } = formMethods;

  const handleRunConditionChange = (selectedValue: RunConditionType) => {
    if (selectedValue === 'run_with_every_record') {
      setValue('criteria', []);
    } else {
      setValue('criteria', [defaultCriteria]);
    }
    void trigger('criteria');
    setRunCondition(selectedValue);
  };

  const onFormSubmit: SubmitHandler<ConditionalRuleFormSchema> = (formValues) => {
    if (isDraftModeEnabled) return;
    const field = table.fields.find((f) => f.key === formValues.targetFieldKey);
    const criteria = formValues.criteria.map((criterion) => {
      const criterionField = table.fields.find((f) => f.key === criterion.field);
      if (criterionField?.type === 'date_time') {
        if (criterion.value.rawTime) {
          let hours = parseInt(criterion.value.rawTime.split(':')[0], 10);
          const minutes = parseInt(criterion.value.rawTime.split(':')[1], 10);

          if (criterion.value.am_pm === 'PM' && hours < 12) {
            hours += 12;
          } else if (criterion.value.am_pm === 'AM' && hours === 12) {
            hours = 0;
          }

          criterion.value.hours = hours.toString().padStart(2, '0');
          criterion.value.minutes = minutes.toString().padStart(2, '0');
          criterion.value.time = `${criterion.value.hours}:${criterion.value.minutes}`;
        }
        if (criterion.value.anyDate || criterionField?.format?.date_format === 'Ignore Date') {
          criterion.value.date = '';
          criterion.value.dateFormatted = '';
          delete criterion.value.anyDate;
          delete criterion.value.anyTime;
        }
        if (criterion.value.anyTime || criterionField?.format?.time_format === 'Ignore Time') {
          criterion.value.time = '';
          criterion.value.hours = '';
          criterion.value.minutes = '';
          criterion.value.rawTime = '';
          criterion.value.am_pm = '';
          delete criterion.value.anyDate;
          delete criterion.value.anyTime;
        }
        return criterion;
      }
      return criterion;
    });

    const { value } = formValues.action;

    if (value && typeof value === 'object' && 'dateFormatted' in value) {
      value.date = value.dateFormatted;
    }

    if (value && typeof value === 'object' && 'to' in value) {
      value.to.date = value.to.dateFormatted;
    }

    const existingKeys = field?.rules?.map((r: ConditionalRule) => Number(r.key)) || [];
    const largestConditionalRuleKey = Math.max(0, ...existingKeys);
    const newRuleKey = largestConditionalRuleKey + 1;

    if (runCondition === 'run_with_every_record') {
      formValues.criteria = [];
    }

    if (!field || !field.rules) return;
    const updatedRules = field.rules.map((r: ConditionalRule) => {
      if (ruleToEdit && r.key === ruleToEdit.key) {
        return {
          ...r,
          values: [
            {
              field: formValues.targetFieldKey,
              type: formValues.action.type,
              value: formValues.action.value,
              input: formValues.action.input,
              criteria
            }
          ],
          ...formValues
        };
      }
      return r;
    });

    if (!ruleToEdit) {
      updatedRules.push({
        key: newRuleKey.toString(),
        values: [
          {
            field: formValues.targetFieldKey,
            type: formValues.action.type,
            value: formValues.action.value,
            input: formValues.action.input,
            criteria
          }
        ],
        ...formValues
      });
    }
    const updatedField = {
      ...field,
      rules: updatedRules
    };

    updateField(
      {
        tableKey: table.key,
        updatedField: updatedField as KnackField
      },
      {
        onSuccess: () => {
          presentToast({
            title: `${t('components.data_table.right_sidebar.rule_saved')} ${t('components.data_table.right_sidebar.refresh_page')}`
          });
          setShouldRenderForm(false);
          onFieldUpdate?.();
          if (setRuleToDuplicate) {
            setRuleToDuplicate(null);
          }
        },
        onError: () => {
          presentToast({
            title: t('components.data_table.right_sidebar.rule_save_error')
          });
        }
      }
    );
  };

  const targetFieldKey = watch('targetFieldKey');
  const isRuleFieldSelected = !!targetFieldKey;

  return (
    <div className="space-y-4 rounded-lg bg-muted p-4">
      <h4 className="font-semibold">
        {t('components.data_table.right_sidebar.conditional_rules.new_conditional_rule')}
      </h4>
      <FormProvider {...formMethods}>
        <Form
          data-testid="conditional-rule-form"
          id="conditional-rules-form"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          {!rule && <ConditionalRuleFormTargetFieldKey table={table} />}

          {isRuleFieldSelected && (
            <>
              {rule ? (
                <RuleFormHeader fieldKey={fieldKey} table={table} />
              ) : (
                <Divider className="my-4" />
              )}
              <Form.Section>
                <Select
                  defaultValue={defaultRunCondition}
                  value={runCondition}
                  onValueChange={handleRunConditionChange}
                >
                  <Select.Trigger
                    id="run-condition-select"
                    placeholder={t('actions.select')}
                    className="w-full truncate rounded-lg"
                    data-testid="run-condition-select"
                    aria-label={t(
                      'components.data_table.right_sidebar.conditional_rules.select_run_condition'
                    )}
                  />
                  <Select.Content>
                    {RUN_CONDITIONS.map((condition) => (
                      <Select.Item
                        key={condition.key}
                        value={condition.key}
                        data-testid="run-condition-item"
                      >
                        {condition.name}
                      </Select.Item>
                    ))}
                  </Select.Content>
                </Select>
              </Form.Section>
              {runCondition === 'custom_criteria' && (
                <CriteriaForm
                  table={table}
                  defaultValues={defaultValues}
                  defaultCriteria={defaultCriteria}
                  setShouldRenderForm={setShouldRenderForm}
                />
              )}
              <ConditionalRuleFormActionValue table={table} defaultValues={defaultValues} />
            </>
          )}
          <div className="mt-4 flex justify-end gap-2">
            <Button
              intent="secondary"
              onClick={() => {
                setShouldRenderForm(false);
                if (setRuleToDuplicate) {
                  setRuleToDuplicate(null);
                }
              }}
            >
              {t('actions.cancel')}
            </Button>
            {isDraftModeEnabled ? (
              <TextTooltip label={t('components.data_table.disabled_because_draftmode')}>
                <Button disabled data-testid="conditional-rule-submit-button">
                  {t('actions.save')}
                </Button>
              </TextTooltip>
            ) : (
              <Button
                type="submit"
                disabled={!isRuleFieldSelected || !formMethods.formState.isValid}
                data-testid="conditional-rule-submit-button"
              >
                {t('actions.save')}
              </Button>
            )}
          </div>
        </Form>
      </FormProvider>
    </div>
  );
}
