import { type WorkBook } from 'xlsx';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type Column,
  type DefaultValue,
  type MatchRule,
  type RawData
} from '@/components/import/types';

type State = {
  fileName: string;
  hasHeaderRow: boolean;
  previewData: RawData[];
  columns: Column[];
  columnsType: string[];
  columnsFormat: string[];
  sheetNames: string[];
  columnVisibility: { [key: string]: boolean };
  totalRecords: number;
  totalVisibleColumns: number;
  selectedSheetIndex: number;
  workbook: WorkBook | null;
  shouldSkipRecordsWithErrors: boolean;
  existingTable: KnackObject | null;
  isFileLoading: boolean;

  // Only required for Advanced Options (Update existing records)
  shouldUpdateExistingRecords: boolean;
  shouldSkipImportIfNoMatch: boolean;
  matchRules: MatchRule[];

  defaultValues: DefaultValue[];
  hasPendingUploads: boolean;
};

type Actions = {
  setFileName: (fileName: string) => void;
  setHasHeaderRow: (hasHeaderRow: boolean) => void;
  setPreviewData: (data: RawData[]) => void;
  setColumns: (columns: Column[]) => void;
  setColumn: (updatedColumn: Column) => void;
  setColumnVisibility: (columnIndex: number) => void;
  setColumnsVisibility: (columnIndices: boolean[]) => void;
  setColumnsType: (columnsType: string[]) => void;
  setColumnsFormat: (columnsFormat: string[]) => void;
  setTotalRecords: (totalRecords: number) => void;
  setExistingTable: (existingTable: KnackObject | null) => void;
  setShouldUpdateExistingRecords: (shouldUpdateExistingRecords: boolean) => void;
  setShouldSkipImportIfNoMatch: (shouldSkipImportIfNoMatch: boolean) => void;
  setMatchRules: (matchRules: MatchRule[]) => void;
  setWorkbook: (workbook: WorkBook | null) => void;
  setShouldSkipRecordsWithErrors: (shouldSkipRecordsWithErrors: boolean) => void;
  setSelectedSheetIndex: (selectedSheet: number) => void;
  resetImportStore: () => void;
  getSheetNames: () => string[];
  getTotalVisibleColumns: () => number;
  getColumnVisibility: (column: Column) => boolean;
  getSelectedSheetIndex: () => number;
  setDefaultValues: (defaultValues: DefaultValue[]) => void;
  setHasPendingUploads: (hasPendingUploads: boolean) => void;
  setIsFileLoading: (isFileLoading: boolean) => void;
};

const initialState: State = {
  fileName: '',
  hasHeaderRow: true,
  previewData: [],
  columns: [],
  columnVisibility: {},
  columnsType: [],
  columnsFormat: [],
  sheetNames: [],
  totalRecords: 0,
  totalVisibleColumns: 0,
  selectedSheetIndex: 0,
  workbook: null,
  shouldSkipRecordsWithErrors: false,
  existingTable: null,
  shouldUpdateExistingRecords: false,
  shouldSkipImportIfNoMatch: false,
  matchRules: [],
  defaultValues: [],
  hasPendingUploads: false,
  isFileLoading: true
};

export const importStore = createWithEqualityFn<State & Actions>()(
  devtools((set, get) => ({
    ...initialState,
    setFileName: (fileName: string) => set({ fileName }, false, 'setFileName'),
    setHasHeaderRow: (hasHeaderRow: boolean) => set({ hasHeaderRow }, false, 'setHasHeaderRow'),
    setPreviewData: (data: RawData[]) => set({ previewData: data }, false, 'setPreviewData'),
    setColumns: (columns: Column[]) => set({ columns }, false, 'setColumns'),
    setColumn: (updatedColumn: Column) =>
      set(
        (state) => ({
          columns: state.columns.map((currentColumn) =>
            currentColumn.header === updatedColumn.header ? updatedColumn : currentColumn
          )
        }),
        false,
        'setColumn'
      ),
    setColumnVisibility: (columnIndex: number) =>
      set(
        (state) => ({
          columnVisibility: {
            ...state.columnVisibility,
            [columnIndex]: !state.columnVisibility[columnIndex]
          }
        }),
        false,
        'setColumnVisibility'
      ),
    setColumnsVisibility: (columnIndices: boolean[]) =>
      set(
        () => {
          const newVisibility = {};
          columnIndices.forEach((isVisible, index) => {
            newVisibility[index.toString()] = isVisible;
          });
          return {
            columnVisibility: newVisibility
          };
        },
        false,
        'setColumnsVisibility'
      ),
    setColumnsType: (columnsType: string[]) => set({ columnsType }, false, 'setColumnsType'),
    setColumnsFormat: (columnsFormat: string[]) =>
      set({ columnsFormat }, false, 'setColumnsFormat'),
    setTotalRecords: (totalRecords: number) => set({ totalRecords }, false, 'setTotalRecords'),
    setExistingTable: (existingTable: KnackObject | null) =>
      set({ existingTable }, false, 'setExistingTable'),
    setShouldUpdateExistingRecords: (shouldUpdateExistingRecords: boolean) =>
      set({ shouldUpdateExistingRecords }, false, 'setShouldUpdateExistingRecords'),
    setShouldSkipImportIfNoMatch: (shouldSkipImportIfNoMatch: boolean) =>
      set({ shouldSkipImportIfNoMatch }, false, 'setShouldSkipImportIfNoMatch'),
    setMatchRules: (matchRules: MatchRule[]) => set({ matchRules }),
    setSelectedSheetIndex: (selectedSheetIndex: number) => set({ selectedSheetIndex }),
    setIsFileLoading: (isFileLoading: boolean) => set({ isFileLoading }, false, 'setIsFileLoading'),
    setWorkbook: (workbook: WorkBook | null) => set({ workbook }, false, 'setWorkbook'),
    setShouldSkipRecordsWithErrors: (shouldSkipRecordsWithErrors: boolean) =>
      set({ shouldSkipRecordsWithErrors }, false, 'setShouldSkipRecordsWithErrors'),
    resetImportStore: () => set(initialState, false, 'resetImportStore'),
    getTotalVisibleColumns: () => Object.values(get().columnVisibility).filter((v) => v).length,
    getColumnVisibility: (column: Column) => get().columnVisibility[column.accessorKey],
    getSheetNames: () => get().workbook?.SheetNames || [],
    getSelectedSheetIndex: () => get().selectedSheetIndex,
    setDefaultValues: (defaultValues: DefaultValue[]) =>
      set({ defaultValues }, false, 'setDefaultValues'),
    setHasPendingUploads: (hasPendingUploads: boolean) =>
      set({ hasPendingUploads }, false, 'setHasPendingUploads')
  })),
  shallow
);

export const useImportStore = importStore;
