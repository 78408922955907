import { nanoid } from 'nanoid';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackObject } from '@/types/schema/KnackObject';
import {
  type FormView,
  type FormViewDisplayRule,
  type FormViewDisplayRuleAction,
  type FormViewFieldInput
} from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { useViewHelpers } from '@/hooks/helpers/useViewHelpers';

export function useFormDisplayRulesHelpers(view: FormView, sourceObject: KnackObject) {
  const { getViewFields } = useViewHelpers();
  const { hasRoleObjects } = useObjectHelpers();
  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const getAvailableViewFieldsForDisplayRules = () =>
    getViewFields({
      view,
      sourceObject,

      // Ignore the user roles fields if no user roles exist in the application
      fieldTypesToIgnore: !hasRoleObjects() ? ['user_roles'] : undefined
    });

  const getInvalidDisplayRules = () => {
    const formViewFieldInputs: FormViewFieldInput[] = [];

    view.groups.forEach((group) => {
      group.columns.forEach((column) => {
        column.inputs.forEach((input) => {
          if (input.type !== 'section_break' && input.type !== 'divider') {
            formViewFieldInputs.push(input);
          }
        });
      });
    });

    const invalidDisplayRules: FormViewDisplayRule[] = [];

    view.rules.fields?.forEach((displayRule) => {
      // If there are no field inputs in the form view, the rule is automatically considered invalid.
      if (formViewFieldInputs.length === 0) {
        invalidDisplayRules.push(displayRule);
        return;
      }

      // A rule is invalid if the criteria is empty, or if any criteria or actions reference a field that is not in the form view.
      const isInvalid =
        displayRule.criteria.length === 0 ||
        displayRule.criteria.some(
          (criteria) =>
            !formViewFieldInputs.some((fieldInput) => fieldInput.field.key === criteria.field)
        ) ||
        displayRule.actions.some(
          (action) =>
            !formViewFieldInputs.some((fieldInput) => fieldInput.field.key === action.field)
        );

      if (isInvalid) {
        invalidDisplayRules.push(displayRule);
      }
    });

    return invalidDisplayRules;
  };

  const getDefaultDisplayRuleCriteria = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const availableFieldsInView = getAvailableViewFieldsForDisplayRules();

    if (availableFieldsInView.length === 0) {
      return undefined;
    }

    const firstAvailableFieldInView = availableFieldsInView[0];

    const defaultDisplayRuleCriteria: KnackCriteria = {
      field: firstAvailableFieldInView.key,
      operator: getDefaultCriteriaOperator(firstAvailableFieldInView, 'display-rule'),
      value: getDefaultCriteriaValue(firstAvailableFieldInView)
    };

    return defaultDisplayRuleCriteria;
  };

  const getDefaultDisplayRuleAction = () => {
    if (!sourceObject.fields) {
      return undefined;
    }

    const firstAvailableFieldInView = getAvailableViewFieldsForDisplayRules()[0];

    if (!firstAvailableFieldInView) {
      return undefined;
    }

    const defaultDisplayRuleAction: FormViewDisplayRuleAction = {
      field: firstAvailableFieldInView.key,
      action: 'show-hide',
      value: ''
    };

    return defaultDisplayRuleAction;
  };

  const getDefaultDisplayRule = () => {
    const defaultDisplayRuleCriteria = getDefaultDisplayRuleCriteria();
    const defaultDisplayRuleAction = getDefaultDisplayRuleAction();

    const defaultDisplayRule: FormViewDisplayRule = {
      key: `display_${nanoid(10)}`,
      criteria: defaultDisplayRuleCriteria ? [defaultDisplayRuleCriteria] : [],
      actions: defaultDisplayRuleAction ? [defaultDisplayRuleAction] : []
    };

    return defaultDisplayRule;
  };

  return {
    getAvailableViewFieldsForDisplayRules,
    getDefaultDisplayRule,
    getDefaultDisplayRuleCriteria,
    getDefaultDisplayRuleAction,
    getInvalidDisplayRules
  };
}
