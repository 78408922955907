import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiChevronDown as ChevronDownIcon } from 'react-icons/hi2';
import { RiBracesLine as BracesIcon } from 'react-icons/ri';
import { Button, Divider, Input, Popover } from '@knack/asterisk-react';

import { type KnackField } from '@/types/schema/KnackField';
import { cn } from '@/utils/tailwind';
import { FieldIcon } from '@/components/FieldIcon';
import { TextTooltip } from '@/components/TextTooltip';
import { emailTemplatesAddedVariables } from '@/pages/roles/role-settings/EmailAddedVariables';
import { type TemplateStringVariable } from '@/pages/roles/role-settings/EmailTemplateSelector';

export function FieldSelector({
  availableFields,
  className,
  contentAlign = 'end',
  onSelectField
}: {
  availableFields: KnackField[];
  contentAlign?: 'start' | 'center' | 'end';
  className?: string;
  onSelectField: (field: TemplateStringVariable) => void;
}) {
  const [t] = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const combinedFieldsList = useMemo(() => {
    const fields: TemplateStringVariable[] = [
      ...availableFields.map((field) => ({
        name: field.name,
        key: field.key,
        type: field.type
      })),
      ...emailTemplatesAddedVariables
    ];
    return fields;
  }, [availableFields]);

  const filteredFieldsList = useMemo(
    () =>
      combinedFieldsList.filter(
        (currentField) =>
          currentField.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          currentField.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (currentField.key && currentField.key.toLowerCase().includes(searchTerm.toLowerCase()))
      ),
    [combinedFieldsList, searchTerm]
  );

  return (
    <Popover open={isOpenPopover} onOpenChange={setIsOpenPopover}>
      <Popover.Trigger asChild>
        <Button
          intent="minimal"
          className={cn(
            'flex h-full items-center px-1 hover:border-l hover:bg-muted focus:-outline-offset-2 data-[state=open]:bg-muted',
            className
          )}
        >
          <BracesIcon size={16} className="shrink-0" />
          <ChevronDownIcon size={16} className="shrink-0" />
        </Button>
      </Popover.Trigger>
      <Popover.Content align={contentAlign} className="max-w-[320px]">
        <div className="p-2 pb-0">
          <p className="p-2 text-xs">
            {t('components.data_table.right_sidebar.tasks.email.insert_field')}
          </p>
          <Input
            className="rounded-none border-0 border-b-subtle p-0 focus:outline-0"
            placeholder={t('components.data_table.select.search')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <Divider />
        <div className="max-h-[250px] overflow-auto p-2">
          <div className="flex flex-col">
            {filteredFieldsList.length === 0 && (
              <p className="p-2 text-default">
                {t('components.data_table.right_sidebar.tasks.email.field_not_found')}
              </p>
            )}
            {filteredFieldsList.map((currentField) => (
              <TextTooltip label={currentField.type} key={currentField.key}>
                <Button
                  intent="minimal"
                  className="w-full py-1.5 font-normal focus:-outline-offset-1"
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectField(currentField);
                    setIsOpenPopover(false);
                  }}
                >
                  <div className="mb-0.5 flex w-full items-center">
                    <FieldIcon name={currentField.type} size={12} className="mr-1" />
                    {currentField.name}
                  </div>
                </Button>
              </TextTooltip>
            ))}
          </div>
        </div>
      </Popover.Content>
    </Popover>
  );
}
