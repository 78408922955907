import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon, HiXMark as RemoveIcon } from 'react-icons/hi2';
import { Fragment } from 'react/jsx-runtime';
import { Button, Form, Input, Label, Select } from '@knack/asterisk-react';
import snakeCase from 'lodash.snakecase';

import { type KnackCriteria } from '@/types/schema/KnackCriteria';
import { type KnackFieldKey, type KnackFieldType } from '@/types/schema/KnackField';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type KnackOperator } from '@/types/schema/KnackOperator';
import { type KnackTask } from '@/types/schema/tasks/KnackTask';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { shouldHideValueBasedOnOperator } from '@/utils/field-operators';
import { cn } from '@/utils/tailwind';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { FieldIcon } from '@/components/FieldIcon';
import { BooleanFieldInput } from '@/components/filters/BooleanFieldInput';
import { ConnectionFieldInput } from '@/components/filters/ConnectionFieldInput';
import { MultipleChoiceFieldInput } from '@/components/filters/MultipleChoiceFieldInput';
import { UserRolesInput } from '@/components/inputs/UserRolesInput';
import {
  NOT_IMPLEMENTED_FIELD_TYPES_IN_TASKS,
  useTaskFormHelpers
} from './helpers/useTaskFormHelpers';

interface TaskCriteriaFormProps {
  table: KnackObject;
}

export function TaskCriteriaForm({ table }: TaskCriteriaFormProps) {
  const [t] = useTranslation();

  const { getDefaultTaskCriteria } = useTaskFormHelpers();
  const { getCriteriaFieldOperators } = useCriteriaHelpers();

  const {
    control,
    register,
    getValues,
    clearErrors: clearFormErrors,
    formState: { errors }
  } = useFormContext<KnackTask>();

  const {
    fields: criteriaFormFields,
    append: appendCriteria,
    remove: removeCriteria,
    update: updateCriteria
  } = useFieldArray({
    name: 'action.criteria',
    control
  });

  const onCriteriaFieldChange = ({
    criteriaIndex,
    newFieldKey
  }: {
    criteriaIndex: number;
    newFieldKey: KnackFieldKey;
  }) => {
    const criteriaToEdit = getValues(`action.criteria.${criteriaIndex}`);
    const previousField = table.fields.find((field) => field.key === criteriaToEdit.field);
    const newField = table.fields.find((field) => field.key === newFieldKey);

    if (!newField) {
      return;
    }

    const getCriteriaValue = () => {
      const multiOptionFieldTypes: KnackFieldType[] = ['boolean', 'multiple_choice', 'connection'];

      // This prevents displaying the previous value in the input when changing the field (e.g. changing from `connection` to `short_text` would show the previously selected record ID in the input)
      // If either the new field or the previous field is a type that uses a select input, we clear the criteria value.
      const shouldClearExistingValue =
        multiOptionFieldTypes.includes(newField.type) ||
        (previousField && multiOptionFieldTypes.includes(previousField.type));

      if (shouldClearExistingValue) {
        switch (newField.type) {
          case 'boolean':
            return true;
          case 'connection':
            return [];
          default:
            return '';
        }
      }

      return criteriaToEdit.value;
    };

    // If the field changes, the selected operator might not be in the new list of operators, so we select the operator in the list if that's the case
    const newFieldOperators = getCriteriaFieldOperators(newField, 'task');
    const isOperatorInList = newFieldOperators.includes(criteriaToEdit.operator);

    updateCriteria(criteriaIndex, {
      field: newFieldKey,
      operator: isOperatorInList ? criteriaToEdit.operator : newFieldOperators[0],
      value: getCriteriaValue()
    });

    clearFormErrors();
  };

  const renderFieldInput = (
    fieldKey: KnackFieldKey,
    selectedOperator: KnackCriteria['operator'],
    index: number
  ) => {
    const selectedField = table.fields.find((field) => field.key === fieldKey);

    if (!selectedField || shouldHideValueBasedOnOperator(selectedOperator)) {
      return null;
    }

    if (selectedField.type === 'connection') {
      return (
        <ConnectionFieldInput<KnackTask>
          field={selectedField}
          formFieldName={`action.criteria.${index}.value`}
        />
      );
    }

    if (selectedField.type === 'user_roles') {
      return (
        <UserRolesInput
          name={`action.criteria.${index}.value`}
          id="task-criteria-user-roles"
          selectedRoleObject={table}
          isCriteria
        />
      );
    }

    if (selectedField.type === 'multiple_choice') {
      return (
        <MultipleChoiceFieldInput<KnackTask>
          field={selectedField}
          formFieldName={`action.criteria.${index}.value`}
        />
      );
    }

    if (selectedField.type === 'boolean') {
      return (
        <BooleanFieldInput<KnackTask>
          field={selectedField}
          formFieldName={`action.criteria.${index}.value`}
        />
      );
    }

    return (
      <Input
        intent={errors?.action?.criteria?.[index]?.value ? 'destructive' : undefined}
        placeholder={t('actions.enter_value')}
        {...register(`action.criteria.${index}.value`)}
      />
    );
  };

  return (
    <Form.Section>
      <div className="mb-2 flex flex-col gap-1">
        <Label className="font-medium">{t('components.rules.when')}</Label>
        <Label data-testid="task-run-under-criteria-label">
          {t(
            `components.data_table.right_sidebar.tasks.criteria.${criteriaFormFields.length > 0 ? 'run_under_criteria' : 'run_on_every_record'}`
          )}
        </Label>
      </div>

      {criteriaFormFields.map((criteriaFormField, criteriaFormFieldIndex) => {
        const criteriaField = table.fields.find((field) => field.key === criteriaFormField.field);
        const criteriaFieldOperators = criteriaField
          ? getCriteriaFieldOperators(criteriaField, 'task')
          : [];

        const fieldValueInput = renderFieldInput(
          criteriaFormField.field,
          criteriaFormField.operator,
          criteriaFormFieldIndex
        );

        const isLastCriteria = criteriaFormFieldIndex === criteriaFormFields.length - 1;

        return (
          <Fragment key={criteriaFormField.id}>
            <div className="my-2 flex">
              <div className="-m-1 flex-1 overflow-hidden p-1">
                <div className="flex gap-2">
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={`action.criteria.${criteriaFormFieldIndex}.field`}
                      render={({ field: { value: fieldKey } }) => (
                        <Select
                          value={criteriaField ? fieldKey : undefined}
                          onValueChange={(newFieldKey: KnackFieldKey) => {
                            onCriteriaFieldChange({
                              criteriaIndex: criteriaFormFieldIndex,
                              newFieldKey
                            });
                          }}
                        >
                          <Select.Trigger
                            placeholder={t('actions.select')}
                            className={cn('w-full', {
                              'border-destructive hover:border-destructive focus:border-destructive focus:outline-destructive':
                                errors?.action?.criteria?.[criteriaFormFieldIndex]?.field
                            })}
                          />
                          <Select.Content>
                            {table.fields.map((field) => (
                              <Select.Item
                                key={field.key}
                                value={field.key}
                                disabled={NOT_IMPLEMENTED_FIELD_TYPES_IN_TASKS.includes(field.type)}
                              >
                                <span className="flex items-center">
                                  <FieldIcon
                                    className="mr-2 shrink-0 text-subtle"
                                    size={16}
                                    name={field.type}
                                  />
                                  {field.name}
                                </span>
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />

                    <FormErrorMessage
                      errors={errors}
                      name={`action.criteria.${criteriaFormFieldIndex}.field`}
                    />
                  </div>
                  <div className="flex-1">
                    <Controller
                      control={control}
                      name={`action.criteria.${criteriaFormFieldIndex}.operator`}
                      render={({ field: { value: operator } }) => (
                        <Select
                          disabled={!criteriaField}
                          value={criteriaField ? operator : undefined}
                          onValueChange={(newOperator: KnackOperator) => {
                            updateCriteria(criteriaFormFieldIndex, {
                              ...getValues(`action.criteria.${criteriaFormFieldIndex}`),
                              operator: newOperator
                            });
                            clearFormErrors();
                          }}
                        >
                          <Select.Trigger placeholder={t('actions.select')} className="w-full" />
                          <Select.Content>
                            {criteriaFieldOperators.map((fieldOperator: string) => (
                              <Select.Item key={fieldOperator} value={fieldOperator}>
                                {t(`operators.${snakeCase(fieldOperator)}`)}
                              </Select.Item>
                            ))}
                          </Select.Content>
                        </Select>
                      )}
                    />
                  </div>
                </div>
                {fieldValueInput && (
                  <div className="mt-2 flex flex-col gap-2">
                    {fieldValueInput}
                    <FormErrorMessage
                      errors={errors}
                      name={`action.criteria.${criteriaFormFieldIndex}.value`}
                    />
                  </div>
                )}

                {!isLastCriteria && (
                  <div className="mt-2 font-medium">{t('components.rules.and_uppercase')}</div>
                )}
              </div>

              <Button
                intent="minimal"
                aria-label={t('components.rules.delete_condition')}
                size="xs"
                className="ml-2 mt-1.5 text-subtle hover:bg-emphasis"
                onClick={() => removeCriteria(criteriaFormFieldIndex)}
              >
                <RemoveIcon size={16} />
              </Button>
            </div>
          </Fragment>
        );
      })}

      <Button
        intent="secondary"
        aria-label={t('components.rules.add_condition')}
        data-testid="add-task-criteria-button"
        onClick={() => {
          const defaultCriteria = getDefaultTaskCriteria(table.fields);
          if (defaultCriteria) {
            appendCriteria(defaultCriteria);
          }
        }}
      >
        <Button.Icon icon={PlusIcon} position="left" />
        {t('components.data_table.right_sidebar.tasks.criteria.add_criteria')}
      </Button>
    </Form.Section>
  );
}
