import { useTranslation } from 'react-i18next';
import { nanoid } from 'nanoid';

import { type KnackCriteriaWithValueType } from '@/types/schema/KnackCriteria';
import { type KnackObject } from '@/types/schema/KnackObject';
import { type FormView, type FormViewSubmitRule } from '@/types/schema/views/FormView';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';

export function useFormSubmitRulesHelpers(sourceObject: KnackObject) {
  const [t] = useTranslation();

  const { getDefaultCriteriaOperator, getDefaultCriteriaValue } = useCriteriaHelpers();

  const getDefaultSubmitRuleCriteria = (formAction: FormView['action']) => {
    if (!sourceObject.fields) {
      return undefined;
    }

    if (sourceObject.fields.length === 0) {
      return undefined;
    }

    const firstFieldInObject = sourceObject.fields[0];

    const defaultSubmitRuleCriteria: KnackCriteriaWithValueType = {
      field: firstFieldInObject.key,
      operator: getDefaultCriteriaOperator(firstFieldInObject, 'submit-rule'),
      value: getDefaultCriteriaValue(firstFieldInObject),
      ...(formAction === 'update' && { value_type: 'custom' })
    };

    return defaultSubmitRuleCriteria;
  };

  const getDefaultSubmitRule = (formAction: FormView['action']) => {
    const defaultSubmitRuleCriteria = getDefaultSubmitRuleCriteria(formAction);

    const defaultSubmitRule: FormViewSubmitRule = {
      key: `submit_${nanoid(10)}`,
      criteria: defaultSubmitRuleCriteria ? [defaultSubmitRuleCriteria] : [],
      action: 'message',
      is_default: false,
      reload_show: false,
      message: t('views.new_view_defaults.form.submit_rule_message')
    };

    return defaultSubmitRule;
  };

  return {
    getDefaultSubmitRule,
    getDefaultSubmitRuleCriteria
  };
}
