import {
  ViewSettingsFiltering,
  type ViewSettingsFilteringOption
} from '@/pages/pages/settings-panel/view-settings/common/filtering/ViewSettingsFiltering';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';

export function ChartFilteringSettings() {
  const { chart, chartSourceObject, updateChart } = useActiveChartContext();

  const handleUpdateChartFilters = (options: Partial<ViewSettingsFilteringOption>) => {
    updateChart({
      filters: {
        ...chart.filters,
        ...options
      }
    });
  };

  return (
    <ViewSettingsFiltering
      sourceObject={chartSourceObject}
      options={chart.filters}
      updateViewSchema={handleUpdateChartFilters}
    />
  );
}
