import { useTranslation } from 'react-i18next';
import { HiPlus as PlusIcon } from 'react-icons/hi';
import { HiTrash as RemoveIcon } from 'react-icons/hi2';
import { Button, Divider, Input, Label } from '@knack/asterisk-react';

import { type KnackFieldKey } from '@/types/schema/KnackField';
import type { ReportViewChartGroup } from '@/types/schema/views/ReportView';
import { useObjectHelpers } from '@/hooks/helpers/useObjectHelpers';
import { BuilderAccordion } from '@/components/BuilderAccordion';
import { getGroupingCategoriesOptions } from '@/pages/pages/page-editor/add-view/helpers/view-schemas/reportViewSchema';
import { type SortingOption } from '@/pages/pages/settings-panel/view-settings/common/sorting/helper';
import { useActiveChartContext } from '@/pages/pages/settings-panel/view-settings/report/chart-settings/ActiveChartContext';
import { CategorySelector } from '@/pages/pages/settings-panel/view-settings/report/data-display/CategorySelector';
import { ReportFiltersDialog } from '@/pages/pages/settings-panel/view-settings/report/data-display/filters/ReportDataDisplayFiltersDialog';
import { ReportFiltersGroupsCard } from '@/pages/pages/settings-panel/view-settings/report/data-display/filters/ReportFiltersGroupsCard';
import { CategoryGroupDateBy } from '@/pages/pages/settings-panel/view-settings/report/data-display/sorting/CategoryGroupDateBy';
import { CategorySortSelector } from '@/pages/pages/settings-panel/view-settings/report/data-display/sorting/CategorySortSelector';

export function ReportDataDisplayCategory() {
  const [t] = useTranslation();

  const { getObjectByKey } = useObjectHelpers();

  const { chart, chartSourceObject, updateChart } = useActiveChartContext();

  const { groups, calculations } = chart;

  const defaultGroup: ReportViewChartGroup = {
    group: `${chartSourceObject.fields[0].type}-${chartSourceObject.fields[0].key}`,
    type: chartSourceObject.fields[0].type,
    field: chartSourceObject.fields[0].key,
    label: chartSourceObject.fields[0].name,
    sort: 'asc'
  };

  const availableCategories = getGroupingCategoriesOptions(chartSourceObject);

  const getConnectedTableFromCategoryField = (fieldKey: KnackFieldKey) => {
    const allSourceObjectConnections = [
      ...chartSourceObject.connections.inbound,
      ...chartSourceObject.connections.outbound
    ];

    const connectedFieldConnection = allSourceObjectConnections.find(
      (connection) => connection.key === fieldKey
    );

    if (!connectedFieldConnection) {
      return undefined;
    }

    return getObjectByKey(connectedFieldConnection.object);
  };

  const shouldShowConnectionFilters = (group: ReportViewChartGroup) =>
    group.type === 'connection' && !!getConnectedTableFromCategoryField(group.field);

  return (
    <BuilderAccordion.Item
      isDefaultOpen
      label={t('pages.element_settings.report.categories.data_display.x_axis')}
    >
      {groups.map((group, index) => {
        const connectedObjectFromCategoryField =
          group.type === 'connection' && shouldShowConnectionFilters(group)
            ? getConnectedTableFromCategoryField(group.field)
            : undefined;

        return (
          <BuilderAccordion.Item
            key={group.field}
            isDefaultOpen
            label={t('pages.element_settings.report.categories.data_display.group_n', {
              count: index + 1
            })}
            className="mb-4 rounded-lg bg-subtle p-2"
            removeItemButton={
              groups.length > 1 ? (
                <Button
                  className="ml-2 h-6 px-1"
                  intent="minimal"
                  size="sm"
                  onClick={() => {
                    updateChart({
                      groups: groups.filter((_, i) => i !== index)
                    });
                  }}
                >
                  <RemoveIcon className="text-subtle" />
                </Button>
              ) : undefined
            }
          >
            <div className="mb-4">
              <Label className="mb-2 block" htmlFor="category-label">
                {t('pages.element_settings.report.categories.data_display.label')}
              </Label>
              <Input
                id="category-label"
                value={group.label}
                onChange={(e) => {
                  const newGroups = [...groups];
                  newGroups[index].label = e.target.value;

                  updateChart({
                    groups: newGroups
                  });
                }}
              />
            </div>
            <div className="mb-4">
              <CategorySelector
                id={`category-selector-${index}`}
                availableCategories={availableCategories}
                onSelectCategory={(categorySelected) => {
                  const newGroups = [...groups];
                  newGroups[index] = {
                    group: categorySelected.value,
                    field: categorySelected.key || defaultGroup.field,
                    label: categorySelected.label,
                    type: categorySelected.type,
                    sort: 'asc'
                  };

                  updateChart({
                    groups: newGroups
                  });
                }}
                defaultValue={group.group}
              />

              <Divider className="mt-4" />
              <CategorySortSelector
                id={`category-sort-selector-${index}`}
                group={group}
                onSelectSortCategory={(value = 'asc') => {
                  const newGroups = [...groups];
                  newGroups[index].sort = value as SortingOption['value'];

                  updateChart({
                    groups: newGroups
                  });
                }}
              />
            </div>
            {connectedObjectFromCategoryField && (
              <div className="mt-4 flex flex-col">
                <Label className="mb-2">
                  {t('pages.element_settings.report.categories.data_display.filters.filters_label')}
                </Label>
                <div className="space-y-4">
                  <ReportFiltersGroupsCard
                    sourceObject={connectedObjectFromCategoryField}
                    filters={group.filters ?? []}
                  />
                  <ReportFiltersDialog
                    sourceObject={connectedObjectFromCategoryField}
                    filters={group.filters ?? []}
                    onFormSubmit={({ criteria }) => {
                      const newGroups = [...groups];
                      newGroups[index].filters = criteria;

                      updateChart({
                        groups: newGroups
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {group.type === 'date_time' && <CategoryGroupDateBy group={group} index={index} />}
          </BuilderAccordion.Item>
        );
      })}
      {chart.type !== 'pie' && (
        <Button
          intent="secondary"
          disabled={groups.length > 1 || calculations.length > 1}
          onClick={() => {
            updateChart({
              groups: [...groups, defaultGroup]
            });
          }}
        >
          <PlusIcon size={16} className="mr-1" />
          {t('pages.element_settings.report.categories.data_display.x_axis_group')}
        </Button>
      )}
    </BuilderAccordion.Item>
  );
}
