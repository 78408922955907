import { Controller, FormProvider, useForm, type SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputWithDisabledText,
  Label,
  Switch,
  useToast
} from '@knack/asterisk-react';
import { z } from 'zod';

import {
  useApplicationSettingsMutation,
  type AppSettingsPayload
} from '@/hooks/api/mutations/useApplicationSettingsMutation';
import { useApplicationQuery } from '@/hooks/api/queries/useApplicationQuery';
import { useSession } from '@/hooks/useSession';
import { FormErrorMessage } from '@/components/errors/FormErrorMessage';
import { TextTooltip } from '@/components/TextTooltip';

export function CoreSettings() {
  const [t] = useTranslation();
  const session = useSession();
  const { data: application } = useApplicationQuery();
  const { presentToast } = useToast();

  const coreSettingsSchema = z.object({
    description: z.string(),
    name: z.string().min(1, {
      message: t('app_settings.general.name_required')
    }),
    slug: z.string().min(1, {
      message: t('app_settings.general.slug_required')
    }),
    settings: z.object({
      from_email: z
        .string()
        .refine((value) => value === '' || z.string().email().safeParse(value).success, {
          message: t('app_settings.general.invalid_email')
        }),
      technical_contact: z
        .string()
        .refine((value) => value === '' || z.string().email().safeParse(value).success, {
          message: t('app_settings.general.invalid_email')
        }),
      isRecordHistoryEnabled: z.boolean(),
      isSystemFieldsEnabled: z.boolean()
    })
  });

  const formMethods = useForm({
    resolver: zodResolver(coreSettingsSchema),
    defaultValues: {
      name: application?.name || '',
      slug: application?.slug || '',
      description: application?.description || '',
      settings: {
        from_email: application?.settings.fromEmail || '',
        technical_contact: application?.settings.technicalContact || '',
        isRecordHistoryEnabled: application?.settings.isRecordHistoryEnabled || false,
        isSystemFieldsEnabled: application?.settings.isSystemFieldsEnabled || false
      }
    }
  });
  const { register, handleSubmit, formState, getValues } = formMethods;
  const { mutate: updateApplicationSettings } = useApplicationSettingsMutation();
  const isTrial = application?.account.isTrial;
  const baseKnackUrl = `${import.meta.env.PUBLIC_LIVE_APP_URL}/${session.account.slug}/`;

  type CoreSettingsSchema = z.infer<typeof coreSettingsSchema>;

  const onFormSubmit: SubmitHandler<CoreSettingsSchema> = (data) => {
    const oldSlug = application?.slug;

    const payload: AppSettingsPayload = {
      description: data.description,
      name: data.name,
      slug: data.slug,
      settings: {
        from_email: data.settings.from_email,
        technical_contact: data.settings.technical_contact,
        isRecordHistoryEnabled: data.settings.isRecordHistoryEnabled,
        isSystemFieldsEnabled: data.settings.isSystemFieldsEnabled
      }
    };

    updateApplicationSettings(payload, {
      onSuccess: () => {
        presentToast({
          title: t('app_settings.settings_saved_successfully')
        });
        const newSlug = getValues('slug');

        if (newSlug && newSlug !== oldSlug) {
          const newUrl = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${application?.account.slug}/${newSlug}/settings/general/core`;
          window.location.replace(newUrl);
        }
      }
    });
  };

  return (
    <FormProvider {...formMethods}>
      <Form data-testid="core-settings-form">
        <Form.Section>
          <Label htmlFor="app_name" isRequired className="text-sm font-medium">
            {t('app_settings.general.app_name')}
          </Label>
          <Input
            id="app_name"
            className="mt-1"
            intent={formState.errors.name ? 'destructive' : 'default'}
            data-testid="name-input"
            {...register('name')}
          />
          <FormErrorMessage errors={formState.errors} name="name" className="mt-1" />
        </Form.Section>
        <Form.Section>
          <Label htmlFor="app_url" isRequired className="text-sm font-medium">
            {t('app_settings.general.app_url')}
          </Label>
          <TextTooltip label={baseKnackUrl} asChild>
            <div>
              <InputWithDisabledText
                id="app-url"
                disabledTextLeft={`${baseKnackUrl.slice(0, 30)}${baseKnackUrl.length > 30 ? '…' : ''}`}
                intent={formState.errors.slug ? 'destructive' : 'default'}
                data-testid="slug-input"
                className="mt-1"
                {...register('slug')}
              />
            </div>
          </TextTooltip>
          <FormErrorMessage errors={formState.errors} name="slug" className="mt-1" />
        </Form.Section>
        <Form.Section data-testid="description-form">
          <Form.Label htmlFor="app_description">
            {t('app_settings.general.app_description')}
          </Form.Label>
          <Input
            id="app_description"
            data-testid="description-input"
            {...register('description')}
          />
        </Form.Section>
        <Form.Section>
          <Form.Label htmlFor="app_email_address">
            {t('app_settings.general.app_email_address')}
          </Form.Label>
          <Input
            id="app_email_address"
            intent={formState.errors.settings?.from_email ? 'destructive' : 'default'}
            data-testid="from-email-input"
            {...register('settings.from_email')}
          />
          {formState.errors && (
            <FormErrorMessage
              errors={formState.errors}
              name="settings.from_email"
              className="mt-1"
            />
          )}
          <p className="mt-2 text-xs text-subtle">
            {t('app_settings.general.app_email_address_description')}
          </p>
        </Form.Section>
        <Form.Section>
          <Form.Label htmlFor="app_technical_contact">
            {t('app_settings.general.app_technical_contact')}
          </Form.Label>
          <Input
            id="app_technical_contact"
            intent={formState.errors.settings?.technical_contact ? 'destructive' : 'default'}
            data-testid="technical-contact-input"
            {...register('settings.technical_contact')}
          />
          {formState.errors && (
            <FormErrorMessage
              errors={formState.errors}
              name="settings.technical_contact"
              className="mt-1"
            />
          )}
          <p className="mt-2 text-xs text-subtle">
            {t('app_settings.general.app_technical_contact_description')}
          </p>
        </Form.Section>
        <Divider />
        {!isTrial && (
          <>
            <Form.Section className="space-y-4">
              <Form.Label htmlFor="enable-record-history-switch">
                {t('app_settings.general.record_history')}
              </Form.Label>
              <div className="flex items-start gap-2">
                <Controller
                  name="settings.isRecordHistoryEnabled"
                  render={({ field }) => (
                    <Switch
                      {...field}
                      id="enable-record-history-switch"
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={(value) => field.onChange(value)}
                    />
                  )}
                />
                <div className="space-y-2">
                  <p className="text-default">{t('app_settings.general.enable_record_history')}</p>
                  <p className="text-xs text-subtle">
                    {t('app_settings.general.enable_record_history_description')}
                  </p>
                </div>
              </div>
            </Form.Section>
            <Divider />
          </>
        )}

        <Form.Section className="space-y-4">
          <Form.Label htmlFor="enable_system_fields_checkbox">
            {t('app_settings.general.system_fields')}
          </Form.Label>
          <div className="flex items-start gap-2">
            {!application?.settings.isSystemFieldsEnabled ? (
              <Controller
                name="settings.isSystemFieldsEnabled"
                render={({ field }) => (
                  <TextTooltip label={t('keywords.coming_soon')}>
                    <Switch
                      {...field}
                      disabled
                      id="enable-record-history-switch"
                      className="mr-2"
                      checked={field.value}
                      onCheckedChange={(value) => field.onChange(value)}
                    />
                  </TextTooltip>
                )}
              />
            ) : (
              <TextTooltip label={t('app_settings.general.system_fields_are_already_created')}>
                <div>
                  <Checkbox checked disabled>
                    {t('app_settings.general.system_fields')}
                  </Checkbox>
                </div>
              </TextTooltip>
            )}
            <div className="space-y-2">
              <p className="text-default">{t('app_settings.general.enable_system_fields')}</p>
              <p className="text-xs text-subtle">
                {t('app_settings.general.enable_system_fields_description')}
              </p>
            </div>
          </div>
        </Form.Section>
        <div className="flex">
          <Button
            data-testid="save-settings-button"
            type="submit"
            onClick={handleSubmit(onFormSubmit)}
            className="ml-auto mt-6"
          >
            {t('app_settings.save_settings')}
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
}
