import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HiPlus as AddIcon, HiPencil as EditIcon } from 'react-icons/hi2';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Dialog } from '@knack/asterisk-react';
import { z } from 'zod';

import {
  type BuilderViewSourceCriteriaRule,
  type BuilderViewSourceSchemaCriteria
} from '@/types/schema/BuilderView';
import { type KnackObject } from '@/types/schema/KnackObject';
import { useCriteriaHelpers } from '@/hooks/helpers/useCriteriaHelpers';
import { SourceFilterDialogGroups } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFilterDialogGroups';
import { SourceFiltersWarningBanner } from '@/pages/pages/settings-panel/view-settings/common/source-filters/SourceFiltersWarningBanner';

interface SourceFiltersDialogProps {
  sourceObject: KnackObject;
  sourceFiltersCriteria: BuilderViewSourceSchemaCriteria;
  onFormSubmit: (data: BuilderViewSourceCriteriaRule[][]) => void;
}

interface SourceFiltersDialogContentProps extends SourceFiltersDialogProps {
  shouldShowSourceFiltersWarning: boolean;
}

export interface SourceFiltersSchema {
  sourceFilters: Array<{
    filters: BuilderViewSourceCriteriaRule[];
  }>;
}

const getDefaultSourceFilters = (sourceFiltersCriteria: BuilderViewSourceSchemaCriteria) => {
  const groupsWithIds = sourceFiltersCriteria.groups.map((group) => ({
    filters: group || []
  }));

  return sourceFiltersCriteria.rules.length > 0
    ? [{ filters: sourceFiltersCriteria.rules }, ...groupsWithIds]
    : groupsWithIds;
};

function SourceFiltersDialogContent({
  sourceObject,
  sourceFiltersCriteria,
  shouldShowSourceFiltersWarning,
  onFormSubmit
}: SourceFiltersDialogContentProps) {
  const [t] = useTranslation();
  const { getDefaultCriteriaOperator, validateCriteriaValues } = useCriteriaHelpers();

  const sourceFiltersItemFormSchema = z
    .custom<SourceFiltersSchema>()
    .superRefine((data, context) => {
      data.sourceFilters.forEach((group, groupIndex) => {
        const criteriaValueError = validateCriteriaValues(group.filters, sourceObject.fields);

        if (criteriaValueError.length > 0) {
          criteriaValueError.forEach((error) => {
            context.addIssue({
              path: [`${groupIndex}:${error.path}`],
              message: t(error.message || 'errors.value_required'),
              code: 'custom'
            });
          });
        }
      });
    });

  const form = useForm<SourceFiltersSchema>({
    resolver: zodResolver(sourceFiltersItemFormSchema),
    defaultValues: {
      sourceFilters: getDefaultSourceFilters(sourceFiltersCriteria)
    }
  });

  const {
    fields: sourceFilters,
    append: addSourceFilter,
    remove: removeSourceFilter
  } = useFieldArray({
    control: form.control,
    name: 'sourceFilters'
  });

  const sourceFiltersFormErrors = form.formState.errors;

  const hasSourceFilters = form.getValues('sourceFilters').length > 0;

  const getDefaultSourceFilterCriteria = () => {
    const firstField = sourceObject.fields[0];

    if (!firstField) {
      return undefined;
    }

    const defaultSourceFilterCriteria: BuilderViewSourceCriteriaRule = {
      field: firstField.key,
      operator: getDefaultCriteriaOperator(firstField, 'filter'),
      value: ''
    };

    return defaultSourceFilterCriteria;
  };

  const updateSourceFilter = (
    index: number,
    value: { criteria: BuilderViewSourceCriteriaRule[] }
  ) => {
    if (value.criteria.length === 0) {
      removeSourceFilter(index);
    } else {
      // Use setValue instead of "update" from useFieldArray because we don't want to unmount and mount the component.
      // setValue allows to update the value without unmounting the component.
      form.setValue(`sourceFilters.${index}.filters`, value.criteria);
    }
  };

  const onSubmit = (data: SourceFiltersSchema) => {
    // Format to remove the filters property and send the arrays directly as the backend expects
    const newData = data.sourceFilters.reduce<BuilderViewSourceCriteriaRule[][]>((acc, group) => {
      if (group.filters.length > 0) {
        acc.push(group.filters);
      }
      return acc;
    }, []);

    onFormSubmit(newData);
  };

  return (
    <form className="w-full" onSubmit={form.handleSubmit(onSubmit)}>
      <Dialog.MainContent>
        <Dialog.Header>
          <Dialog.Title>
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.source_filters'
            )}
          </Dialog.Title>
          <Dialog.Description className="text-xs text-subtle">
            {t(
              'pages.element_settings.common.categories.data_display.general_settings.source_filters_description'
            )}
          </Dialog.Description>
        </Dialog.Header>
        {hasSourceFilters && (
          <div className="mt-6">
            {shouldShowSourceFiltersWarning && <SourceFiltersWarningBanner className="mb-4" />}
            <p className="mb-4">
              {t(
                'pages.element_settings.common.categories.data_display.general_settings.show_the_records_match'
              )}
            </p>
            <div>
              {sourceFilters.length > 0 &&
                sourceFilters.map(
                  (sourceFilterGroup, sourceFilterGroupIndex) =>
                    sourceFilterGroup.filters.length > 0 && (
                      <div key={`${sourceFilterGroup.id}-source-filters-group`}>
                        {sourceFilterGroupIndex > 0 && (
                          <p className="my-2">{t('components.rules.or_uppercase')}</p>
                        )}
                        <div className="space-y-2 rounded-lg bg-subtle p-3">
                          <SourceFilterDialogGroups
                            sourceFilterGroup={sourceFilterGroup.filters}
                            sourceObject={sourceObject}
                            sourceFilterGroupIndex={sourceFilterGroupIndex}
                            sourceFiltersFormErrors={sourceFiltersFormErrors}
                            onChange={(data) => updateSourceFilter(sourceFilterGroupIndex, data)}
                          />
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        )}
        <Button
          intent="secondary"
          className="mt-4 gap-1"
          onClick={() => {
            const defaultSourceFilterCriteria = getDefaultSourceFilterCriteria();

            if (!defaultSourceFilterCriteria) {
              return;
            }

            addSourceFilter({ filters: [defaultSourceFilterCriteria] });
          }}
          data-testid="source-filters-modal-add-source-filter-button"
        >
          <Button.Icon icon={AddIcon} />
          {hasSourceFilters
            ? t(
                'pages.element_settings.common.categories.data_display.general_settings.filter_group'
              )
            : t(
                'pages.element_settings.common.categories.data_display.general_settings.source_filters'
              )}
        </Button>
      </Dialog.MainContent>
      <Dialog.Footer>
        <Dialog.Close asChild>
          <Button intent="minimal">{t('actions.cancel')}</Button>
        </Dialog.Close>
        <Button type="submit">{t('actions.apply')}</Button>
      </Dialog.Footer>
    </form>
  );
}

export function SourceFiltersDialog({
  sourceObject,
  sourceFiltersCriteria,
  onFormSubmit
}: SourceFiltersDialogProps) {
  const [t] = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const hasSourceFilters = sourceFiltersCriteria.rules.length > 0;
  const shouldShowSourceFiltersWarning =
    sourceFiltersCriteria.match === 'all' && sourceFiltersCriteria.rules.length > 1;

  const handleSourceFiltersSubmit = (data: BuilderViewSourceCriteriaRule[][]) => {
    onFormSubmit(data);
    setIsDialogOpen(false);
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        <Button
          intent="secondary"
          className="gap-1"
          size="sm"
          data-testid="view-settings-add-source-filters-button"
        >
          <Button.Icon icon={hasSourceFilters ? EditIcon : AddIcon} />
          {hasSourceFilters
            ? t(
                'pages.element_settings.common.categories.data_display.general_settings.edit_source_filters'
              )
            : t(
                'pages.element_settings.common.categories.data_display.general_settings.source_filters'
              )}
        </Button>
      </Dialog.Trigger>
      <Dialog.Content>
        <SourceFiltersDialogContent
          sourceObject={sourceObject}
          sourceFiltersCriteria={sourceFiltersCriteria}
          shouldShowSourceFiltersWarning={shouldShowSourceFiltersWarning}
          onFormSubmit={handleSourceFiltersSubmit}
        />
      </Dialog.Content>
    </Dialog>
  );
}
